import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-callus',
  templateUrl: './callus.component.html',
  styleUrls: ['./callus.component.css']
})
export class CallusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
