import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { constant } from '../constant';
import Utils from '../utils';
import { CommonService } from '../services/common.service';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// import { NgxXml2jsonService } from 'ngx-xml2json';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { CkycService, ProposalCreationForHealth } from '../services/reliance.service';
//import * as JsonToXML from "js2xmlparser";
//import { promise } from 'protractor';

declare var $: any
interface occupation {
    occupation_code: string;
    occupation_name: string;
}

@Component({
    selector: 'app-proposal',
    templateUrl: './proposal.component.html',
    styleUrls: ['./proposal.component.css']
})
export class ProposalComponent implements OnInit {

    @ViewChild('abhipgform', { static: true }) abhipgform: ElementRef;
    IDProofArr = [
        {
            id: 'IDNO3',
            value: 'Driving License'
        }, {
            id: 'IDNO2',
            value: 'PAN'
        }, {
            id: 'IDNO1',
            value: 'Passport'
        }, {
            id: 'IDNO4',

            value: 'Voter ID'
        }
    ]

    occupations: occupation[] = [
        { occupation_code: '6', occupation_name: 'A/C DUCTMAN' },
        { occupation_code: '7', occupation_name: 'Advocate / Lawyer' },
        { occupation_code: '8', occupation_name: 'Businessman/Industrialist Large Scale' },
        { occupation_code: '9', occupation_name: 'Businessman/Industrialist Medium Scale' },
        { occupation_code: '10', occupation_name: 'Businessman/Industrialist Small Scale' },
        { occupation_code: '11', occupation_name: 'Carpenter' },
        { occupation_code: '12', occupation_name: 'Chartered Accountants' },
        { occupation_code: '13', occupation_name: 'Clerical' },
        { occupation_code: '14', occupation_name: 'Cook' },
        { occupation_code: '15', occupation_name: 'ELECTRIC ARC WELDER' },
        { occupation_code: '16', occupation_name: 'Executive - Middle Level' },
        { occupation_code: '17', occupation_name: 'Executive - Senior Level' },
        { occupation_code: '18', occupation_name: 'Executive Junior Level' },
        { occupation_code: '19', occupation_name: 'Farming' },
        { occupation_code: '20', occupation_name: 'Housemaid' },
        { occupation_code: '21', occupation_name: 'HouseWife' },
        { occupation_code: '22', occupation_name: 'INDUSTRIAL WIRING ELECTRICIAN' },
        { occupation_code: '23', occupation_name: 'Labourer' },
        { occupation_code: '24', occupation_name: 'Mason' },
        { occupation_code: '25', occupation_name: 'Not Working' },
        { occupation_code: '26', occupation_name: 'Others ' },
        { occupation_code: '27', occupation_name: 'OXYGEN WELDER' },
        { occupation_code: '28', occupation_name: 'Reinforcing Fitter' },
        { occupation_code: '29', occupation_name: 'Retired' },
        { occupation_code: '30', occupation_name: 'Salesperson' },
        { occupation_code: '31', occupation_name: 'Self employed Professional' },
        { occupation_code: '32', occupation_name: 'Service' },
        { occupation_code: '33', occupation_name: 'Shop owner' },
        { occupation_code: '34', occupation_name: 'Software Professional' },
        { occupation_code: '35', occupation_name: 'Steel Fixer' },
        { occupation_code: '36', occupation_name: 'Student' },
        { occupation_code: '37', occupation_name: 'Supervisor' },
        { occupation_code: '2047', occupation_name: 'Business Man Medium Scale' },
        { occupation_code: '2048', occupation_name: 'BUSINESS' },
        { occupation_code: '2049', occupation_name: 'Agriculturist' },
        { occupation_code: '2050', occupation_name: 'Business Owner' },
        { occupation_code: '2051', occupation_name: 'Doctor' },
        { occupation_code: '2052', occupation_name: 'Lawyer' },
        { occupation_code: '2053', occupation_name: 'Professional' },
        { occupation_code: '2054', occupation_name: 'Retired/Pensioner' },
        { occupation_code: '2055', occupation_name: 'Salaried' },
        { occupation_code: '2056', occupation_name: 'Self Employed' },
    ];

    proposal: any = {
        first_name: '',
        middle_name: '',
        last_name: '',
        emailId: '',
        date_of_birth: {
            dd: '',
            mm: '',
            yy: ''
        },
        feb: true,
        height: {
            ft: '',
            inch: "0"
        },
        address: {
            house_no: "",
            area: "",
            landmark: "",
            nationality: "Indian",
            pincode: "",
            city_id: "",
            state: '',
            state_id: ""
        },

        weight: '',
        gender: "",
        occupation: '',
        occupationName: '',
        marital_status: "",
        annual_income: '',
        insured_member: [],
        nominee: {
            first_name: '',
            last_name: '',
            email: '',
            mobileno: '',
            date_of_birth: {
                dd: '',
                mm: '',
                yy: ''
            },
            age: '',
            relationship_with_proposer: '',
            relation_value: '',
            addressline1: '',
            addressline2: '',
            state: '',
            city: '',
            pincode: ''
        },
        appointee: {
            appointeeName: '',
            appointeeAge: '',
            appointeeRelationship: ''
        },
        relations: []
    };

    premium_collection: any = {
        coverage: 0,
        premium_year: 0,
        premium_amout: 0,
        gst: 0,
        final_premium: 0,
        TotalDiscountAmount: 0,
    };
    otp = '';
    //modalRef: BsModalRef;
    @ViewChild('otppopup', { static: true }) otppopup: BsModalRef
    noofyear = [];
    ABHI_PG_LANDING: string;
    CARE_PG_LANGING: string;
    userid: string;
    session_id: string;
    step2nextbtn = false;
    eldestmemberAge: any;
    eldestmemberGender: any;
    stateName: string;
    actulaRel = [];
    questions = [];
    proposer_pincode: string;
    section: string;
    savenshareObj: any = {};
    modalRef: BsModalRef;
    modalRef2: BsModalRef;
    ped_section = false;
    imdisabled = false;
    totalmember = [];
    seletedRelArr = [];
    //lifestyleFlag = false;
    dd = [];
    YY = [];
    snsBtn = 'Save & Share';
    snsdisabled = false;
    occupation = [];
    stateList = [];
    cityList = [];
    cityList2 = [];
    weight = [];
    age = [];
    areaList = [];
    appointeeAge = []
    relationShip = [];
    lifestyleList = [];
    medical_histories = [];
    medical = [];
    step1 = true;
    step2 = false;
    step3 = false;
    step4 = false; //** */
    step5 = false;
    step6 = false;
    step14 = false;
    noOfChild = 0;
    proposer_step = false;
    insured_member_step = false;
    kyc_step = false;
    nominee_step = false;
    medical_history_step = false;
    cover_type_slug: string;
    cover_type: string;
    previewFlag = false;
    appointee = false;
    medi1 = false;
    medi2 = false;
    medi3 = false;
    medi4 = false;
    medi5 = false;
    medi6 = false;
    coverageDisplay: string;
    finalPremiumDisplay: string;
    cityDisabled = true;
    nomineeRelations = [];
    btnText = 'Next';
    btndisabled = true;
    plan_id: string;
    company_id: string;
    step: string;
    premium_id = '';
    fullURL: string;
    plandetails: any = {
        product_master: {},
        company: {},
        premium: {},
        coverage: {}
    };

    kyc_details: any = {
        pan_card: '',
        date_of_birth: '',
        ispanCardVerfied: false,
        fullname: ''
    };

    medical_details_status: any = {
        status: false,
        value: '',
    }


    base_url: string;
    gst: number;
    util = new Utils;
    avlbPincodes = [];
    genericrelationShip = [];
    carebody: any = {};
    responsejson = {};
    construct: any;
    agee: any;
    city: any;
    gender: any;
    coverage: string;
    currentYr: number;
    abhi: any = {};
    carepg: any = {};
    // step: string;
    linkclicked = false;
    backbtnhidefromapp = '0';
    countmedicalQus = 0;
    medical_questions: any = [
        {
            question_title: 'Hypertension/ High blood pressure',
            DiseaseID: '60',
            existing_since: {
                mm: '', yy: ''
            },
        },
        {
            question_title: 'Diabetes/ High blood sugar/Sugar in urine',
            DiseaseID: '61',
            existing_since: {
                mm: '', yy: ''
            },
        }
    ];
    company_special_id: string;
    data: any[] = [];
    qus: any = { existing_since: { mm: '', yy: '' } };
    panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    constructor(
        private commonService: CommonService,
        private _location: Location,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        // private ngxXml2jsonService: NgxXml2jsonService,
        private modalService: BsModalService,
        private ngxLoader: NgxUiLoaderService,
        // reliance
        private ckycService: CkycService,
        private ProposalService: ProposalCreationForHealth

    ) { }

    backClicked() {
        //this._location.back();
        this.router.navigate([`health`]);
    }

    onpincodeSelect(event: { target: { value: any; }; }) {
        console.log('Pincode : ', event)
        this.proposal.address.pincode = event.target.value
    }

    async ngOnInit() {
        //console.log(moment())
        $("html, body").animate({ scrollTop: 0 }, 600);
        this.userid = localStorage.getItem("getquoteuserID") || '';
        this.session_id = localStorage.getItem("session_id") || '';
        this.backbtnhidefromapp = localStorage.getItem('backbtnhidefromapp') || '0';
        this.fullURL = constant.hosting_endpoint + this.router.url + '&sess=' + this.session_id;
        //this.fullURL = this.router.url;
        var d = new Date();
        this.ABHI_PG_LANDING = constant.abhi_landing_page_url;
        this.CARE_PG_LANGING = constant.religare_payment_url;
        this.currentYr = d.getFullYear();
        //const random = this.util.randomString(15);
        this.base_url = constant.api_endpoint;
        this.gst = constant.gstval;
        let tab = localStorage.getItem('tab');
        var user: {
            construct: any;
            pincode: any; userName: string; emailId: any; contactNumber: any; from_pincode: string; to_pincode: string; state_id: any; gender: string; city_id: any; age: any;
        };
        if (tab === 'I') {
            user = JSON.parse(localStorage.getItem('userData1'));
        } else if (tab === 'F') {
            user = JSON.parse(localStorage.getItem('userData2'));
        } else {
            user = JSON.parse(localStorage.getItem('userData3'));
        }
        // this.lifestyleList = await this.getLifeStyle();
        // console.log(this.lifestyleList);

        for (let i = 1; i <= 31; i++) {
            if (i.toString().length === 1) {
                this.noofyear.push(i + 1)
                this.dd.push('0' + i);
            } else {
                this.dd.push(i);
            }

        }
        for (let x = 1; x <= 100; x++) {
            this.weight.push(x);
            this.age.push(x);
            this.YY.push((this.currentYr - 90) + x);
            if (x > 18) {
                this.appointeeAge.push(x);
            }
        }
        console.log('========New Session============');
        this.populateInsuredMemberForm(user);
        let userArr = user.userName.split(" ");
        console.log(userArr);

        this.proposal.first_name = userArr[0];
        this.savenshareObj.emailId = user.emailId;
        this.savenshareObj.contactNumber = user.contactNumber;

        //set pincodes
        for (let i = parseInt(user.from_pincode); i <= parseInt(user.to_pincode); i++) {
            this.avlbPincodes.push(i.toString())
        }
        //console.log("avlbPincodes : ", this.avlbPincodes)

        // this.proposal.insured_member[0]['first_name'] = userArr[0];

        if (userArr.length == 3) {
            this.proposal.middle_name = userArr[1];
            this.proposal.last_name = userArr[2];
        } else if (userArr.length == 2) {
            this.proposal.last_name = userArr[1];
        }

        console.log(this.proposal.insured_member);


        this.proposal.emailId = user.emailId;
        this.proposal.contactNumber = user.contactNumber;
        this.proposal.address.state_id = '21';
        this.proposal.date_of_birth.yy = this.currentYr - user.age;
        this.proposal.address.pincode = user.pincode;
        this.construct = user.construct
        console.log(this.construct);

        // this.welcomeFormFillingSMS();
        this.getstateList();
        this.proposal.gender = (user.gender == '0') ? 'MALE' : 'FEMALE';
        if (this.proposal.address.state_id) {
            this.cityList = [
                {
                    id: "1",
                    city_name: 'mumbai'
                }
            ];
            this.proposal.address.city_id = '1';
            this.proposal.address.city = this.cityList.filter((el: { id: any; }) => {
                return el.id === this.proposal.address.city_id
            })[0]['city_name'];
            console.log(this.proposal.address.city);

        }

        this.getNomineeRelations();
        // this.goto(2);

        // const review = localStorage.getItem('review');
        // console.log('review');

        // if (review.length != 0) {
        //     // this.proposal=review[0];
        // }

        // premium_collection

        let premium_collectionData = JSON.parse(localStorage.getItem('premium_collection'));

        if (premium_collectionData) {
            this.premium_collection.coverage = premium_collectionData.coverage;
            this.premium_collection.premium_year = premium_collectionData.premium_year;
            this.premium_collection.premium_amout = premium_collectionData.premium_amout;
            this.premium_collection.gst = premium_collectionData.gst;
            this.premium_collection.final_premium = premium_collectionData.final_premium;
            this.premium_collection.TotalDiscountAmount = premium_collectionData.TotalDiscountAmount;
        }

        let currentStep = JSON.parse(localStorage.getItem('step'));
        if (currentStep == 2) {
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('proposer');
        } else if (currentStep == 3) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('medical details');
        } else if (currentStep == 4) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('kyc');
        } else if (currentStep == 5) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            console.log('nominee');

        } else if (currentStep == 6) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            console.log('review');
            // localStorage.setItem('step', '0');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
            let medical_details_present = localStorage.getItem('medical_details_present');
            let medical_details = JSON.parse(localStorage.getItem('medical_details'));
            if (proposal_Proposer) {
                this.proposal.first_name = proposal_Proposer.first_name;
                this.proposal.middle_name = proposal_Proposer.middle_name;
                this.proposal.last_name = proposal_Proposer.last_name;
                this.proposal.date_of_birth.dd = proposal_Proposer.date_of_birth.dd;
                this.proposal.date_of_birth.mm = proposal_Proposer.date_of_birth.mm;
                this.proposal.date_of_birth.yy = proposal_Proposer.date_of_birth.yy;
                this.proposal.marital_status = proposal_Proposer.marital_status;
                this.proposal.emailId = proposal_Proposer.emailId;
                this.proposal.contactNumber = proposal_Proposer.contactNumber;
                this.proposal.annual_income = proposal_Proposer.annual_income;
                this.proposal.address.house_no = proposal_Proposer.address.house_no;
                this.proposal.address.area = proposal_Proposer.address.area;
                this.proposal.address.city = proposal_Proposer.address.city;
                this.proposal.address.state = proposal_Proposer.address.state;
                this.proposal.address.pincode = proposal_Proposer.address.pincode;
            }
            if (proposal_insured_member) {
                this.proposal.insured_member = proposal_insured_member;
                console.log(this.proposal.insured_member);

            }
            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
            if (medical_details_present) {
                if (medical_details_present == 'no') {

                    this.medical_details_status.status = false;
                    this.medical_details_status.value = 'NO';
                } else {
                    this.medical_details_status.status = true;
                    this.medical_details_status.value = 'YES';

                }
            }
            if (medical_details) {
                this.proposal.insured_member.medical_questions = medical_details;
            }
        } else {
            this.step1 = true;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = false;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.nominee_step = false;
        }

        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
        let setdate_of_birth = proposal_insured_member[0].date_of_birth;
        this.kyc_details.date_of_birth = `${setdate_of_birth.dd}-${setdate_of_birth.mm}-${setdate_of_birth.yy}`;
        console.log(this.kyc_details.date_of_birth);
        this.kyc_details.fullname = `${proposal_insured_member[0].first_name}/${proposal_insured_member[0].middle_name}/${proposal_insured_member[0].last_name}`

    }

    async populateInsuredMemberForm(user) {
        let proposalform = this.commonService.relations().filter(el => el.value == user.construct);
        console.log(proposalform);


        this.noOfChild = proposalform[0]['nochild'];
        console.log(this.noOfChild);

        // this.medical_questions = await this.getMedicalQuestionarries();
        // console.log(this.medical_questions);

        if (this.proposal.marital_status === 'Single') {

            console.log(proposalform[0]['form']['relations']);

        }

        //let totalmember = proposalform[0]['totalmember'];
        for (var member of proposalform[0]['form']) {
            console.log(member)
            let yyyy = []
            if (member.membertype == '1c') {
                var startingYr = (this.currentYr - 25);
                for (let x = 1; x <= 25; x++) {
                    yyyy.push(startingYr + x)
                }

            } else {
                var Yr = (this.currentYr - 90);
                for (let x = 1; x <= 90; x++) {
                    yyyy.push(Yr + x)
                }
            }
            //console.log(member.membertype, ":", yyyy)
            let userArr = user.userName.split(" ");
            let middle_name = '';
            let last_name = '';
            let first_name = '';
            if (userArr.length == 3) {
                first_name = userArr[0]
                middle_name = userArr[1];
                last_name = userArr[2];
            } else if (userArr.length == 2) {
                first_name = userArr[0]
                last_name = userArr[1];
            }

            this.proposal.insured_member.push(
                {
                    relationship_with_user: member.relations,
                    memberDef: member.memberTypeDef,
                    membertype: member.membertype,
                    relArr: member.relations,
                    dateOfBirthYear: yyyy,
                    symptomStartYr: yyyy,
                    first_name: first_name,
                    middle_name: middle_name,
                    last_name: last_name,
                    weight: '',
                    occupation: '',
                    marital_status: (member.membertype == '1c') ? 'Single' : '',
                    maritaldropdown: (member.membertype == '1c') ? true : false,
                    gender: '',
                    height: {
                        ft: '', inch: '0'
                    },
                    date_of_birth: {
                        dd: '',
                        mm: '',
                        yy: ''
                    },
                    emailId: '',
                    contactNumber: '',
                    IDProofType: '',
                    IDProofNumber: '',
                    isdisabled: false,
                    has_ped: false,
                    medical_questions: this.medical_questions.map((el) => {
                        return {
                            question_title: el.question_title,
                            DiseaseID: el.DiseaseID,
                            existing_since: {
                                mm: '', yy: ''
                            },
                        }
                    }),
                    mh: false,
                    marital_status_flag: true,
                    genderfreez: false,
                    nominee: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        mobileno: '',
                        date_of_birth: {
                            dd: '',
                            mm: '',
                            yy: ''
                        },
                        age: '',
                        relationship_with_proposer: '',
                        relation_value: '',
                        addressline1: '',
                        addressline2: '',
                        state: '',
                        city: '',
                        pincode: ''
                    },
                    appointee: {
                        appointeeName: '',
                        appointeeAge: '',
                        appointeeRelationship: ''
                    },
                }
            )
        }
        console.log(this.proposal.insured_member)
    }

    getNomineeRelations() {
        if (this.company_special_id == '3') {
            this.nomineeRelations = this.commonService.abhiNomineeRel();
        } else {
            this.nomineeRelations = this.commonService.nomineeRelations();
        }
        console.log("Nominee RelationShip: ", this.nomineeRelations)
    }


    changeMaritalStatus() {
        console.log(this.proposal.marital_status);

        if (this.proposal.marital_status === 'Single') {
            this.nomineeRelations = this.nomineeRelations.filter(nom => nom.value !== 'Spouse');
        } else {
            this.getNomineeRelations();
        }
    }
    changeRelation(e: { target: { value: any; }; }, index: number) {
        let val = e.target.value;
        console.log(val);

        if (index === 0) {
            this.seletedRelArr = [];
            console.log(this.seletedRelArr)
        }
        if (val == '345') {
            console.log(this.proposal)
            this.proposal.insured_member[index].isdisabled = true;
            this.proposal.insured_member[index]['first_name'] = this.proposal.first_name;
            this.proposal.insured_member[index]['middle_name'] = this.proposal.middle_name;
            this.proposal.insured_member[index]['last_name'] = this.proposal.last_name;
            this.proposal.insured_member[index]['occupation'] = this.proposal.occupation;
            this.proposal.insured_member[index]['marital_status'] = this.proposal.marital_status;
            this.proposal.insured_member[index]['gender'] = this.proposal.gender;
            this.proposal.insured_member[index]['date_of_birth']['dd'] = this.proposal.date_of_birth.dd;
            this.proposal.insured_member[index]['date_of_birth']['mm'] = this.proposal.date_of_birth.mm;
            this.proposal.insured_member[index]['date_of_birth']['yy'] = this.proposal.date_of_birth.yy;
            this.proposal.insured_member[index]['height']['ft'] = this.proposal.height.ft;
            this.proposal.insured_member[index]['height']['inch'] = this.proposal.height.inch;
            this.proposal.insured_member[index]['weight'] = this.proposal.weight;
            this.proposal.insured_member[index]['emailId'] = this.proposal.emailId;
            this.proposal.insured_member[index]['contactNumber'] = this.proposal.contactNumber;
            this.proposal.insured_member[index]['IDProofType'] = this.proposal.IDProofType;
            this.proposal.insured_member[index]['IDProofNumber'] = this.proposal.IDProofNumber;
        } else {
            this.proposal.insured_member[index].isdisabled = false;
            this.proposal.insured_member[index]['gender'] = '';
            this.proposal.insured_member[index]['marital_status'] = '';
            this.proposal.insured_member.isdisabled = false;
            this.proposal.insured_member[index]['first_name'] = '';
            this.proposal.insured_member[index]['middle_name'] = '';
            this.proposal.insured_member[index]['last_name'] = '';
            this.proposal.insured_member[index]['occupation'] = '';
            this.proposal.insured_member[index]['marital_status'] = '';
            this.proposal.insured_member[index]['gender'] = '';
            this.proposal.insured_member[index]['date_of_birth']['dd'] = '';
            this.proposal.insured_member[index]['date_of_birth']['mm'] = '';
            this.proposal.insured_member[index]['date_of_birth']['yy'] = '';
            this.proposal.insured_member[index]['height']['ft'] = '';
            this.proposal.insured_member[index]['height']['inch'] = '';
            this.proposal.insured_member[index]['weight'] = '';
            this.proposal.insured_member[index]['emailId'] = '';
            this.proposal.insured_member[index]['contactNumber'] = '';
            this.proposal.insured_member[index]['IDProofType'] = '';
            this.proposal.insured_member[index]['IDProofNumber'] = '';
        }
        // let relMatch = this.relationShip.filter(e => e.relation_slug == val);
        // if (relMatch.length <= 0) {
        //     this.util.errorDialog("This relation is not available");
        //     this.proposal.insured_member[index]['relationship_with_user'] = '';
        // }
        // console.log(relMatch)
        // this.proposal.insured_member[index]['relationship_with_proposer'] = (relMatch.length > 0) ? relMatch[0]['relation_code'] : null;
        // this.seletedRelArr[index] = val;
        // console.log(this.seletedRelArr)

        if (this.construct == '2a') {
            if (this.proposal.insured_member[0]['relationship_with_user'] == 'self') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'spouse', value: 'Spouse' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'spouse') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'self', value: 'Self' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'father') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'mother', value: 'Mother' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'mother') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'father', value: 'Father' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'father-in-law') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'mother-in-law', value: 'Mother-in-Law' }];
            } else if (this.proposal.insured_member[0]['relationship_with_user'] == 'mother-in-law') {
                this.proposal.insured_member[1]['relArr'] = [{ key: 'father-in-law', value: 'Father-in-Law' }];
            }
        } else {
            // =======Remove Selected Insured members relation (ADULT)===============

            this.proposal.insured_member.forEach((mem: { IDProofType: string; membertype: string; }, i: number) => {
                let IDProofNam = this.IDProofArr.find(el => el.id == mem.IDProofType);
                if (IDProofNam) {
                    this.proposal.insured_member[i]['IDProofName'] = this.IDProofArr.find(el => el.id == mem.IDProofType)['value']
                }
                if (i > index && mem.membertype == '1a' && (this.noOfChild > 0)) {
                    let rels = [{ key: 'self', value: 'Self' }, { key: 'spouse', value: 'Spouse' }]
                    this.proposal.insured_member[i]['relArr'] = rels.filter((el) => {
                        if (this.proposal.marital_status == 'Single') {
                            return el.key != 'spouse' && !this.seletedRelArr.includes(el.key)
                        } else {
                            return !this.seletedRelArr.includes(el.key)
                        }
                    })
                } else if (i > index && mem.membertype == '1c' && (this.noOfChild > 0)) {
                    // =======SPECIAL CASE FRO CHILD===============
                    this.proposal.insured_member[i]['relArr'] = [{ key: 'son', value: 'Son' }, { key: 'daughter', value: 'Daughter' }]
                }
            })
        }


        if (val == '320') {
            if (this.proposal.gender == 'MALE') {
                this.proposal.insured_member[index]['gender'] = 'FEMALE';
                this.proposal.insured_member[index]['marital_status'] = 'Married';
                this.proposal.insured_member[index]['maritaldropdown'] = true;
                this.proposal.insured_member[index]['genderfreez'] = true;
            } else {
                this.proposal.insured_member[index]['gender'] = 'MALE';
                this.proposal.insured_member[index]['genderfreez'] = true;
                this.proposal.insured_member[index]['marital_status'] = 'Married';
                this.proposal.insured_member[index]['maritaldropdown'] = true;
            }
        } else {
            this.proposal.insured_member[index]['genderfreez'] = false;
            this.proposal.insured_member[index]['maritaldropdown'] = false;
        }

        if (val == '321') {
            this.proposal.insured_member[index]['marital_status'] = 'Single';
            this.proposal.insured_member[index]['gender'] = 'MALE';
            this.proposal.insured_member[index]['genderfreez'] = true;
            this.proposal.insured_member[index]['maritaldropdown'] = true;
        } else if (val == '322') {
            this.proposal.insured_member[index]['marital_status'] = 'Single';
            this.proposal.insured_member[index]['gender'] = 'FEMALE';
            this.proposal.insured_member[index]['genderfreez'] = true;
            this.proposal.insured_member[index]['maritaldropdown'] = true;
        }

        if (val == '319' || val == '2058' || val == '2063') {
            this.proposal.insured_member[index]['gender'] = 'MALE';
            this.proposal.insured_member[index]['genderfreez'] = true;
            this.proposal.insured_member[index]['marital_status'] = 'Married';
        } else if (val == '1255' || val == '2057' || val == '2063') {
            this.proposal.insured_member[index]['gender'] = 'FEMALE';
            this.proposal.insured_member[index]['genderfreez'] = true;
            this.proposal.insured_member[index]['marital_status'] = 'Married';
        }
    }

    // Assuming data is a property of your component


    getmedical_questions(event: any, diseaseId: string) {
        const selectedValue = event.target.value;

        // Use the existing medical_questions array from your component
        const existingEntry = this.medical_questions.find(question => question.DiseaseID === diseaseId);

        if (selectedValue === 'YES') {
            // Check if there is an entry with the given DiseaseID
            const existingEntries = this.data.filter(question => question.DiseaseID === diseaseId);
            localStorage.setItem('medical_details_present', 'yes');

            if (existingEntries.length === 0) {
                // If there is no entry, add a new entry
                this.data.push(existingEntry);
            } else {
                // If there are existing entries, you can decide how to handle it
                // For example, you might want to create a new entry or update the existing ones.
                // Here, we are creating a new entry with a different title.
                this.data.push({
                    question_title: `Another Question for DiseaseID ${diseaseId}`,
                    DiseaseID: diseaseId,
                    existing_since: {
                        mm: '', yy: ''
                    },
                });
            }
        } else if (selectedValue === 'NO') {
            // Remove all entries with the given DiseaseID
            this.data = this.data.filter(question => question.DiseaseID !== diseaseId);
            localStorage.setItem('medical_details_present', 'no');
        }

        console.log(this.data);
    }

    Updatemedical_questions(current: any, type: string, diseaseId: string) {
        const selectedValue = current.target.value;

        // Use the existing medical_questions array from your component
        const existingEntry = this.medical_questions.find(question => question.DiseaseID === diseaseId);

        // Check if there is an entry with the given DiseaseID in this.data
        const existingDataEntryIndex = this.data.findIndex(question => question.DiseaseID === diseaseId);

        if (existingDataEntryIndex === -1) {
            // If there is no entry, add a new entry
            console.log(' If there is no entry, add a new entry');

            this.data.push({
                question_title: existingEntry ? existingEntry.question_title : '',
                DiseaseID: diseaseId,
                existing_since: {
                    mm: type === 'existing_since_mm' ? selectedValue : '',
                    yy: type === 'existing_since_yy' ? selectedValue : ''
                },
            });
        } else {
            // If there is an existing entry, update the existing_since.mm or existing_since.yy
            console.log('If there is an existing entry, update the existing_since.mm or existing_since.yy');
            console.log(type);

            if (type === 'existing_since_mm') {
                console.log(existingDataEntryIndex);

                this.data[existingDataEntryIndex].existing_since.mm = selectedValue;
            } else if (type === 'existing_since_yy') {
                this.data[existingDataEntryIndex].existing_since.yy = selectedValue;
            }
        }

        console.log(this.data);

        localStorage.setItem('medical_details', JSON.stringify(this.data))
    }

    kycDetailsPanVerified() {
        const requestData = {
            "PAN": this.kyc_details.pan_card,
            "DOB": this.kyc_details.date_of_birth,
            "ReturnURL": "www.google.com",
            "UNIQUEID": "445555"
        };
        // Check if the PAN is valid before making the API request
        if (this.panRegex.test(requestData.PAN)) {
            this.ckycService.getPanVerified(requestData).subscribe(
                (response) => {
                    console.log('Success', response);
                    // Handle the successful response here
                    this.toastr.error(response.message, 'Error');

                    if (response.PAN_Verified == 'true') {
                        this.kyc_details.ispanCardVerfied = true;
                        let collectData = {
                            "PanNo": this.kyc_details.pan_card,
                            "DOB": this.kyc_details.date_of_birth,
                            "PAN_Verified": response.PAN_Verified,
                            "KYC_Verified": response.KYC_Verified,
                            "CKYC": response.kyc_data.CKYC.result.PERSONAL_DETAILS.CKYC_NO,
                            "IsDocumentUpload": false,
                            "IsForm60": false,
                        }
    
                        localStorage.setItem('PAN_Verified', JSON.stringify(collectData))
                    }
                    if (response.PAN_Verified == 'false') {
                        this.kyc_details.ispanCardVerfied = false;
                        let collectData = {
                            "PanNo": this.kyc_details.pan_card,
                            "DOB": this.kyc_details.date_of_birth,
                            "PAN_Verified": response.PAN_Verified,
                            "KYC_Verified": response.KYC_Verified,
                            "CKYC": response.kyc_data.CKYC.result.PERSONAL_DETAILS.CKYC_NO,
                            "IsDocumentUpload": false,
                            "IsForm60": false,
                        }
    
                        localStorage.setItem('PAN_Verified', JSON.stringify(collectData))
                    }

                },
                (error) => {
                    console.error('Error', error);
                    // Handle the error here
                    this.kyc_details.ispanCardVerfied = true;
                }
            );


        } else {
            alert('Invalid PAN format. Please enter a valid PAN.');
        }
    }





    // goto(step: number) {
    //     var regex = constant.emailvalidateregex;
    //     var mobileNoregex = constant.mobilevalidateregex;
    //     if (step == 2) {
    // //-----------Proposer--------------
    // this.step2nextbtn = false;
    // this.seletedRelArr = [];
    // console.log('Proposer');


    // //this.proposal.insured_member[0]['relationship_with_user'] = '';
    // this.proposal.insured_member = this.proposal.insured_member.map((el) => {
    //     if (this.noOfChild > 0 && el.membertype == '1a') {
    //         el['relArr'] = [{ key: 'self', value: 'Self' }, { key: 'spouse', value: 'Spouse' }]
    //     } else if (this.noOfChild == 0 && el.membertype == '1a') {
    //         if (this.proposal.marital_status == 'Single') {
    //             el['relArr'] = el.relArr.filter(e => e.key != 'spouse' && e.key != 'mother-in-law' && e.key != 'father-in-law');
    //         } else if (this.proposal.marital_status == 'Married') {
    //             el['relArr'] = this.commonService.relationshipWithProposer()
    //         }
    //     } else {
    //         el['relArr'] = el.relArr
    //     }
    //     return el;
    // });

    // console.log(this.proposal.insured_member);



    // if (!this.stateName) {
    //     this.util.errorDialog("State name is undefined", "Error");
    //     return;
    // }

    // let panregex = constant.pannumberregex;
    // console.log(this.avlbPincodes.includes(this.proposal.address.pincode))
    // if (this.proposal.first_name == '') {
    //     this.toastr.error("Please enter your First Name", "Error");
    // } else if (!constant.onlyalphabetregex.test(this.proposal.first_name)) {
    //     this.toastr.error('Only alphabet allowed in First Name', "Error");
    //     return false;
    // } else if (this.proposal.last_name === '') {
    //     this.toastr.error("Please enter your Last Name", "Error");
    // } else if (!constant.onlyalphabetregex.test(this.proposal.last_name)) {
    //     this.toastr.error('Only alphabet allowed in Last Name', "Error");
    //     return false;
    // } else if (this.proposal.emailId == '') {
    //     this.toastr.error("Please enter an email address", "Error");
    // } else if (!regex.test(this.proposal.emailId)) {
    //     this.toastr.error("Please enter a valid email address", "Error");
    // } else if (!mobileNoregex.test(this.proposal.contactNumber)) {
    //     this.toastr.error('Please enter a valid 10 digit mobile number', "Error");
    //     return false;
    // } else if (!this.proposal.date_of_birth.dd || !this.proposal.date_of_birth.mm || !this.proposal.date_of_birth.yy) {
    //     this.toastr.error("Date Of birth is required", "Error");
    // } else if (!this.proposal.marital_status) {
    //     this.toastr.error("Select your Marital Status", "Error");
    // } else if (!this.proposal.gender) {
    //     this.toastr.error("Select your Gender", "Error");
    // } else if (!this.proposal.height.ft) {
    //     this.toastr.error("Select your Height", "Error");
    // } else if (!this.proposal.weight) {
    //     this.toastr.error("Select your Weight", "Error");
    // } else if ((this.noOfChild > 0) && (this.proposal.marital_status == 'Single')) {
    //     this.toastr.error("Wrong Marital Status", "Error");
    // } else if (!this.proposal.annual_income) {
    //     this.toastr.error("Annual Income is required", "Error");
    // } else if (!this.proposal.pan && this.plandetails.OneyearFinalPremium >= 50000) {
    //     this.toastr.error("PAN No is required", "Error");
    // } else if (this.plandetails.OneyearFinalPremium >= 50000 && this.proposal.pan && !panregex.test(this.proposal.pan)) {
    //     this.toastr.error("Valid PAN No is required", "Error");
    // } else if (this.company_special_id != '1' && !this.proposal.occupation) {
    //     this.toastr.error("Occupation is required", "Error");
    // } else if (this.company_special_id == '4' && !this.proposal.IDProofType) {
    //     this.toastr.error("IDProofType is required", "Error");
    // } else if (this.company_special_id == '4' && !this.proposal.IDProofNumber) {
    //     this.toastr.error("IDProofNumber is required", "Error");
    // } else if (!this.proposal.address.house_no) {
    //     this.toastr.error("Enter your Flat,House No, Street", "Error");
    // } else if (!this.proposal.address.area) {
    //     this.toastr.error("Enter your Area", "Error");
    // } else if (!this.proposal.address.pincode) {
    //     this.toastr.error("Enter your pincode", "Error");
    // } else if (!this.proposal.address.city_id) {
    //     this.toastr.error("Enter your city", "Error");
    // } else if (!this.proposal.address.state_id) {
    //     this.toastr.error("Enter your state", "Error");
    // } else if (this.construct == '2a' && this.proposal.marital_status == 'Single') {
    //     this.toastr.error("Wrong Marital Status", "Error");
    // } else {
    //     const dob = `${this.proposal.date_of_birth.mm}/${this.proposal.date_of_birth.dd}/${this.proposal.date_of_birth.yy}`; //12/18/1988
    //     let monthdays = this.util.getDaysInMonth(this.proposal.date_of_birth.mm, this.proposal.date_of_birth.yy);
    //     console.log("monthdays : ", monthdays);
    //     const proposerAge = this.util.calculateAge(dob);
    //     console.log(proposerAge);

    //     let pannum = (this.proposal.pan) ? this.proposal.pan.split("") : [];
    //     console.log("PAN : ", pannum)
    //     if (this.proposal.pan && pannum.length > 0 && pannum[3] != 'P') {
    //         this.toastr.error("Invalid Pan Number", "Error");
    //     } else if (this.proposal.date_of_birth.dd > monthdays) {
    //         this.toastr.error("Invalid Date Of Birth", "Error");
    //     } else if (proposerAge < 18) {
    //         this.toastr.error("Proposer should be 18+", "Error");
    //     } else {
    //         this.activityCapture('proposer')
    //     }
    // }
    //         localStorage.setItem('proposal_Proposer', JSON.stringify(this.proposal));
    //     } else if (step == 3) {
    //         //-----------Insured Member------------
    //         console.log('power 3');
    //         this.seletedRelArr = [];
    //         this.actulaRel = []
    //         let valid = true;
    //         var d = new Date();
    //         var n = d.getFullYear();
    //         var maxAge = 0;
    //         var eldestmemberGender = '';
    //         let proposerAge = this.util.calculateAge(`${this.proposal.date_of_birth.mm}/${this.proposal.date_of_birth.dd}/${this.proposal.date_of_birth.yy}`);
    //         for (var el of this.proposal.insured_member) {
    //             if (!el.relationship_with_user) {
    //                 this.toastr.error(`${el.memberDef} relationship with proposer`, "Error");
    //                 valid = false;
    //                 break;
    //             }
    //             if (!el.first_name || !el.last_name) {
    //                 this.toastr.error(`${el.memberDef} First name and Last name is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (!constant.onlyalphabetregex.test(el.first_name)) {
    //                 this.toastr.error('Only alphabet allowed in First Name', "Error");
    //                 valid = false;
    //                 break;
    //             } else if (!constant.onlyalphabetregex.test(el.last_name)) {
    //                 this.toastr.error('Only alphabet allowed in Last Name', "Error");
    //                 valid = false;
    //                 break;
    //             } else if (!el.weight) {
    //                 this.toastr.error(`${el.memberDef} weight is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (!el.marital_status) {
    //                 this.toastr.error(`${el.memberDef} marital status is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (!el.gender) {
    //                 this.toastr.error(`${el.memberDef} gender is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (this.step != '1' && !el.occupation) {
    //                 this.toastr.error(`${el.memberDef} occupation is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (!el.height.ft || !el.height.inch) {
    //                 this.toastr.error(`${el.memberDef} height is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (!el.date_of_birth.dd || !el.date_of_birth.mm || !el.date_of_birth.yy) {
    //                 this.toastr.error(`${el.memberDef} Date Of Birth is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (this.step == '4' && !el.emailId) {
    //                 this.toastr.error(`${el.memberDef} Email is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (this.step == '4' && !regex.test(el.emailId)) {
    //                 this.toastr.error("Please enter a valid email address", "Error");
    //                 valid = false;
    //                 break;
    //             } else if (this.step == '4' && !el.contactNumber) {
    //                 this.toastr.error(`${el.memberDef} contactNumber is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (this.step == '4' && !el.IDProofType) {
    //                 this.toastr.error(`${el.memberDef} IDProofType is required`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (this.step == '4' && !el.IDProofNumber) {
    //                 this.toastr.error(`${el.memberDef} IDProofNumber is required`, "Error");
    //                 valid = false;
    //                 break;
    //             }

    //             let dob = `${el.date_of_birth.mm}/${el.date_of_birth.dd}/${el.date_of_birth.yy}`; //mm/dd/yyyy
    //             var varDate = new Date(`${el.date_of_birth.yy}-${el.date_of_birth.mm}-${el.date_of_birth.dd}`).getTime();
    //             var today = new Date().getTime();
    //             let monthdays = this.util.getDaysInMonth(el.date_of_birth.mm, el.date_of_birth.yy);
    //             console.log("monthdays : ", monthdays);

    //             if (varDate > today) {
    //                 this.toastr.error(`Wrong date of birth choosen for ${el.memberDef}`, "Error");
    //                 valid = false;
    //                 break;
    //             } else if (el.date_of_birth.dd > monthdays) {
    //                 this.toastr.error("Invalid Date Of Birth", "Error");
    //                 valid = false;
    //                 break;
    //             }

    //             let memberAge = this.util.calcAgeinDecimal(dob);
    //             //alert(memberAge);

    //             if (memberAge > maxAge) {
    //                 maxAge = memberAge
    //                 eldestmemberGender = el.gender;
    //             }

    //             if (el.construct == '1a' && memberAge < 18) {
    //                 this.toastr.error(`${el.memberDef} age should be 18+`, "Error");
    //                 valid = false;
    //                 break;
    //             }

    //             if (el.construct == '1c' && ((memberAge > constant.childamaxAgelimit) || (memberAge < constant.childminAge))) {
    //                 this.toastr.error(`Child age should be between > 91 days and < 24 yrs`, "Error");
    //                 valid = false;
    //                 break;
    //             }

    //             if (el.relationship_with_user == 'father' || el.relationship_with_user == 'mother') {
    //                 let ageDiffwithproposer = memberAge - proposerAge;
    //                 if (ageDiffwithproposer < 18) {
    //                     this.toastr.error(`Incorrect Age of ${el.memberDef}`, "Error");
    //                     valid = false;
    //                     break;
    //                 }
    //             }
    //         }; // end of for loop

    //         this.eldestmemberAge = maxAge;
    //         this.eldestmemberGender = eldestmemberGender;

    //         console.log("valid : ", valid)

    //         //this.checkPremium();
    //         this.step1 = false;
    //         this.step2 = false;
    //         this.step3 = true;
    //         this.insured_member_step = true;

    //     } else if (step == 4) {

    //         console.log('power 4');
    //         if (!this.proposal.nominee.first_name || !this.proposal.nominee.last_name) {
    //             this.toastr.error(`Nominee first name  and last name is required`, "Error");
    //         } else if (!constant.onlyalphabetregex.test(this.proposal.nominee.first_name)) {
    //             this.toastr.error('Only alphabet allowed in First Name', "Error");
    //             return false;
    //         } else if (!constant.onlyalphabetregex.test(this.proposal.nominee.last_name)) {
    //             this.toastr.error('Only alphabet allowed in Last Name', "Error");
    //             return false;
    //         } else if (!this.proposal.nominee.mobileno && this.step == '3') {
    //             this.toastr.error(`Nominee Mobile No is required`, "Error");
    //         } else if (this.step == '3' && !mobileNoregex.test(this.proposal.nominee.mobileno)) {
    //             this.toastr.error('Please enter a valid 10 digit mobile number of nominee', "Error");
    //             return false;
    //         } else if (!this.proposal.nominee.age) {
    //             this.toastr.error(`Nominee age is required`, "Error");
    //         } else if (this.proposal.nominee.age < 18 && (!this.proposal.appointee.appointeeName || !this.proposal.appointee.appointeeAge || !this.proposal.appointee.appointeeRelationship)) {
    //             this.toastr.error(`Please enter all appointee details`, "Error");
    //         } else if (!this.proposal.nominee.relationship_with_proposer) {
    //             this.toastr.error(`Nominee relation is required`, "Error");
    //         } else if (this.proposal.nominee.relationship_with_proposer == 'Father' || this.proposal.nominee.relationship_with_proposer == 'Mother' || this.proposal.nominee.relationship_with_proposer == 'Father-in-law' || this.proposal.nominee.relationship_with_proposer == 'Mother-in-law') {
    //             const dob = `${this.proposal.date_of_birth.mm}/${this.proposal.date_of_birth.dd}/${this.proposal.date_of_birth.yy}`; //12/18/1988
    //             const proposerAge = this.util.calculateAge(dob);
    //             let agediff = this.proposal.nominee.age - proposerAge;
    //             if (agediff < 18) {
    //                 this.toastr.error(`Nominee age difference should be minimimum 18 years with proposer`, "Error");
    //             } else {
    //                 this.activityCapture('nominee');

    //             }


    //         } else {
    //             this.step1 = false;
    //             this.step2 = false;
    //             this.step3 = false;
    //             this.step4 = true;
    //             this.step5 = false;
    //             this.medical_history_step = true;
    //             this.activityCapture('nominee')
    //         }

    //         const index = 0;  // Specify the index where you want to push the new object
    //         // Update the nominee object properties with actual data
    //         const newNominee = {
    //             first_name: this.proposal.nominee.first_name,
    //             last_name: this.proposal.nominee.last_name,
    //             email: 'update_with_actual_email',
    //             mobileno: 'update_with_actual_mobile_number',
    //             date_of_birth: {
    //                 dd: 'update_with_actual_day',
    //                 mm: 'update_with_actual_month',
    //                 yy: 'update_with_actual_year'
    //             },
    //             age: this.proposal.nominee.age,
    //             relationship_with_proposer: this.proposal.nominee.relationship_with_proposer,
    //             relation_value: 'update_with_actual_relation_value',
    //             addressline1: 'update_with_actual_address1',
    //             addressline2: 'update_with_actual_address2',
    //             state: 'update_with_actual_state',
    //             city: 'update_with_actual_city',
    //             pincode: 'update_with_actual_pincode'
    //         };

    //         // Push the new nominee object to the array at the specified index
    //         this.proposal.insured_member[index]['nominee'] = newNominee;
    //         // this.step3 = true;
    //         console.log(this.proposal.insured_member);

    //         localStorage.setItem('review', JSON.stringify(this.proposal.insured_member));

    //     } else if (step == 5) {
    //         // if (!this.proposal.address.pincode) {
    //         //     this.toastr.error("Enter your pincode", "Error");
    //         // } else {
    //         //     let nextStep = true;
    //         //     let personalAccidentCount = 0
    //         //     for (let im of this.proposal.insured_member) {
    //         //         let dieseases = im.medical_history.filter((e: { formvisible: boolean; }) => e.formvisible == true);
    //         //         let personalAccident = im.medical_questions.find((el: { question_code: string; }) => el.question_code == 'isPersonalAccidentApplicable');
    //         //         // if (im.has_ped && dieseases.length == 0) {
    //         //         //     this.toastr.error("Please select disease for " + im.first_name + ' ' + im.last_name, "Error");
    //         //         //     nextStep = false;
    //         //         //     break;
    //         //         // } else 
    //         //         //alert(this.plandetails.plan_code)
    //         //         if (im.medical_questions.length > 0 && personalAccident && personalAccident['isPersonalAccidentApplicable'] == 'true') {
    //         //             personalAccidentCount++
    //         //         } else if (im.has_ped && dieseases.length > 0) {
    //         //             for (let mh of dieseases) {
    //         //                 console.log(mh)
    //         //                 if (mh.symptom_start_date.mm == '' || mh.symptom_start_date.yy == '') {
    //         //                     this.toastr.error("Please provide symptom start month & year for " + im.first_name + ' ' + im.last_name, "Error");
    //         //                     nextStep = false;
    //         //                     break;
    //         //                 } else if (mh.current_status == '') {
    //         //                     this.toastr.error("Please provide current status for " + im.first_name + ' ' + im.last_name, "Error");
    //         //                     nextStep = false;
    //         //                     break;
    //         //                 } else if (mh.health_declined == '') {
    //         //                     this.toastr.error("Please provide Health Insurance been declined status for " + im.first_name + ' ' + im.last_name, "Error");
    //         //                     nextStep = false;
    //         //                     break;
    //         //                 }
    //         //             };
    //         //         }

    //         //         if (this.plandetails.plan_code == 'DIABETESIND' || this.plandetails.plan_code == 'DIABETESFMLY') {
    //         //             //======== If Diabetes Plan selected the diabetes detail validation ===========
    //         //             for (let qus of im.medical_questions) {
    //         //                 console.log("Diabetes Qus : ", qus)
    //         //                 // if (qus.question_code == 'isDiabetes' && (qus.diabetes.diabetesMellitus == "" || qus.diabetes.insulinProblem == "" || qus.diabetes.bloodSugar == "" || qus.diabetes.serumCreatinine == "" || qus.diabetes['HBA1C'] == "")) {
    //         //                 //     this.toastr.error("Please provide all the details of Diabetes of " + im.first_name + ' ' + im.last_name, "Error");
    //         //                 //     nextStep = false;
    //         //                 //     break;
    //         //                 // }
    //         //                 if (qus.question_code == 'isDiabetes' && qus.diabetes.insulinProblem == "true" && qus.diabetes.insulinFrom == "") {
    //         //                     this.toastr.error("Please provide insuline taking from years " + im.first_name + ' ' + im.last_name, "Error");
    //         //                     nextStep = false;
    //         //                     break;
    //         //                 }
    //         //             }
    //         //         }
    //         //     }

    //         //     if (this.step == '2' && this.plandetails.plan_code != 'REDCARPET' && this.plandetails.plan_code != 'REDCARPETFMLY' && this.plandetails.plan_code != 'STARYSIND' && this.plandetails.plan_code != 'STARYSFMLY' && this.plandetails.plan_code != 'MCINEW' && (personalAccidentCount > 1 || personalAccidentCount == 0)) {
    //         //         this.toastr.error("Any one insured member should be added for personal accident benefit in this section", "Error");
    //         //         nextStep = false;
    //         //         return;
    //         //     }

    //         //     //======================================================
    //         //     if (nextStep) {
    //         //         this.proposal.insured_member = this.proposal.insured_member.map((rel: { [x: string]: any[]; occupation: any; has_ped: any; }) => {

    //         //             rel['occupationName'] = this.occupation.filter(e => rel.occupation == e.occupation_code);

    //         //             // rel['lifestyle'] = rel.lifestyle.filter(e => {
    //         //             //     return e.checked && e.consumes
    //         //             // })
    //         //             if (rel.has_ped) {
    //         //                 this.ped_section = true;
    //         //             }
    //         //             console.log(rel)
    //         //             return rel
    //         //         })

    //         //         this.activityCapture('medical')
    //         //         //this.previewSMS()
    //         //     } else {
    //         //         this.step1 = false;
    //         //         this.step2 = false;
    //         //         this.step3 = false;
    //         //         this.step4 = true;
    //         //         this.step6 = false;
    //         //         this.medical_history_step = true;
    //         //     }
    //         // }
    //         this.step1 = false;
    //         this.step2 = false;
    //         this.step3 = false;
    //         this.step4 = false;
    //         this.step6 = true;
    //         this.medical_history_step = false;
    //         console.log(this.proposal.insured_member)
    //     }
    //     console.log("Step : ", step);

    //     $("html, body").animate({ scrollTop: 0 }, 600);
    // }

    goto(step: number) {
        var regex = constant.emailvalidateregex;
        var mobileNoregex = constant.mobilevalidateregex;

        if (step == 2) {
            //-----------Proposer data collect--------------
            this.step2nextbtn = false;
            this.seletedRelArr = [];
            console.log('Proposer');

            //this.proposal.insured_member[0]['relationship_with_user'] = '';
            this.proposal.insured_member = this.proposal.insured_member.map((el) => {
                if (this.noOfChild > 0 && el.membertype == '1a') {
                    el['relArr'] = [{ key: 'self', value: 'Self' }, { key: 'spouse', value: 'Spouse' }]
                } else if (this.noOfChild == 0 && el.membertype == '1a') {
                    if (this.proposal.marital_status == 'Single') {
                        el['relArr'] = el.relArr.filter(e => e.key != 'spouse' && e.key != 'mother-in-law' && e.key != 'father-in-law');
                    } else if (this.proposal.marital_status == 'Married') {
                        el['relArr'] = this.commonService.relationshipWithProposer()
                    }
                } else {
                    el['relArr'] = el.relArr
                }
                return el;
            });

            console.log(this.proposal.insured_member);



            if (!this.stateName) {
                this.util.errorDialog("State name is undefined", "Error");
                return;
            }

            let panregex = constant.pannumberregex;
            console.log(this.avlbPincodes.includes(this.proposal.address.pincode))
            if (this.proposal.first_name == '') {
                this.toastr.error("Please enter your First Name", "Error");
            } else if (!constant.onlyalphabetregex.test(this.proposal.first_name)) {
                this.toastr.error('Only alphabet allowed in First Name', "Error");
                return false;
            } else if (this.proposal.last_name === '') {
                this.toastr.error("Please enter your Last Name", "Error");
            } else if (!constant.onlyalphabetregex.test(this.proposal.last_name)) {
                this.toastr.error('Only alphabet allowed in Last Name', "Error");
                return false;
            } else if (this.proposal.emailId == '') {
                this.toastr.error("Please enter an email address", "Error");
            } else if (!regex.test(this.proposal.emailId)) {
                this.toastr.error("Please enter a valid email address", "Error");
            } else if (!mobileNoregex.test(this.proposal.contactNumber)) {
                this.toastr.error('Please enter a valid 10 digit mobile number', "Error");
                return false;
            } else if (!this.proposal.date_of_birth.dd || !this.proposal.date_of_birth.mm || !this.proposal.date_of_birth.yy) {
                this.toastr.error("Date Of birth is required", "Error");
            } else if (!this.proposal.marital_status) {
                this.toastr.error("Select your Marital Status", "Error");
            } else if (!this.proposal.gender) {
                this.toastr.error("Select your Gender", "Error");
            } else if (!this.proposal.height.ft) {
                this.toastr.error("Select your Height", "Error");
            } else if (!this.proposal.weight) {
                this.toastr.error("Select your Weight", "Error");
            } else if ((this.noOfChild > 0) && (this.proposal.marital_status == 'Single')) {
                this.toastr.error("Wrong Marital Status", "Error");
            } else if (!this.proposal.annual_income) {
                this.toastr.error("Annual Income is required", "Error");
            } else if (!this.proposal.occupation) {
                this.toastr.error("Occupation is required", "Error");
            } else if (!this.proposal.address.house_no) {
                this.toastr.error("Enter your Flat,House No, Street", "Error");
            } else if (!this.proposal.address.area) {
                this.toastr.error("Enter your Area", "Error");
            } else if (!this.proposal.address.pincode) {
                this.toastr.error("Enter your pincode", "Error");
            } else if (!this.proposal.address.city_id) {
                this.toastr.error("Enter your city", "Error");
            } else if (!this.proposal.address.state_id) {
                this.toastr.error("Enter your state", "Error");
            } else if (this.construct == '2a' && this.proposal.marital_status == 'Single') {
                this.toastr.error("Wrong Marital Status", "Error");
            } else {
                const dob = `${this.proposal.date_of_birth.mm}/${this.proposal.date_of_birth.dd}/${this.proposal.date_of_birth.yy}`; //12/18/1988
                let monthdays = this.util.getDaysInMonth(this.proposal.date_of_birth.mm, this.proposal.date_of_birth.yy);
                console.log("monthdays : ", monthdays);
                const proposerAge = this.util.calculateAge(dob);
                console.log(proposerAge);
                if (this.proposal.date_of_birth.dd > monthdays) {
                    this.toastr.error("Invalid Date Of Birth", "Error");
                } else if (proposerAge < 18) {
                    this.toastr.error("Proposer should be 18+", "Error");
                } else {
                    this.activityCapture('proposer')
                }
            }
            localStorage.setItem('proposal_Proposer', JSON.stringify(this.proposal));
            localStorage.setItem('step', '2');
            // next step
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('insures memeber');

        }
        if (step == 3) {
            //-----------Insured Member------------
            this.seletedRelArr = [];
            this.actulaRel = []
            let valid = true;
            var d = new Date();
            var n = d.getFullYear();
            var maxAge = 0;
            var eldestmemberGender = '';
            let proposerAge = this.util.calculateAge(`${this.proposal.date_of_birth.mm}/${this.proposal.date_of_birth.dd}/${this.proposal.date_of_birth.yy}`);
            for (var el of this.proposal.insured_member) {
                if (!el.relationship_with_user) {
                    this.toastr.error(`${el.memberDef} relationship with proposer`, "Error");
                    valid = false;
                    break;
                }
                if (!el.first_name || !el.last_name) {
                    this.toastr.error(`${el.memberDef} First name and Last name is required`, "Error");
                    valid = false;
                    break;
                } else if (!constant.onlyalphabetregex.test(el.first_name)) {
                    this.toastr.error('Only alphabet allowed in First Name', "Error");
                    valid = false;
                    break;
                } else if (!constant.onlyalphabetregex.test(el.last_name)) {
                    this.toastr.error('Only alphabet allowed in Last Name', "Error");
                    valid = false;
                    break;
                } else if (!el.weight) {
                    this.toastr.error(`${el.memberDef} weight is required`, "Error");
                    valid = false;
                    break;
                } else if (!el.marital_status) {
                    this.toastr.error(`${el.memberDef} marital status is required`, "Error");
                    valid = false;
                    break;
                } else if (!el.gender) {
                    this.toastr.error(`${el.memberDef} gender is required`, "Error");
                    valid = false;
                    break;
                } else if (this.step != '1' && !el.occupation) {
                    this.toastr.error(`${el.memberDef} occupation is required`, "Error");
                    valid = false;
                    break;
                } else if (!el.height.ft || !el.height.inch) {
                    this.toastr.error(`${el.memberDef} height is required`, "Error");
                    valid = false;
                    break;
                } else if (!el.date_of_birth.dd || !el.date_of_birth.mm || !el.date_of_birth.yy) {
                    this.toastr.error(`${el.memberDef} Date Of Birth is required`, "Error");
                    valid = false;
                    break;
                } else if (this.step == '4' && !el.emailId) {
                    this.toastr.error(`${el.memberDef} Email is required`, "Error");
                    valid = false;
                    break;
                } else if (this.step == '4' && !regex.test(el.emailId)) {
                    this.toastr.error("Please enter a valid email address", "Error");
                    valid = false;
                    break;
                } else if (this.step == '4' && !el.contactNumber) {
                    this.toastr.error(`${el.memberDef} contactNumber is required`, "Error");
                    valid = false;
                    break;
                }

                let dob = `${el.date_of_birth.mm}/${el.date_of_birth.dd}/${el.date_of_birth.yy}`; //mm/dd/yyyy
                var varDate = new Date(`${el.date_of_birth.yy}-${el.date_of_birth.mm}-${el.date_of_birth.dd}`).getTime();
                var today = new Date().getTime();
                let monthdays = this.util.getDaysInMonth(el.date_of_birth.mm, el.date_of_birth.yy);
                console.log("monthdays : ", monthdays);

                if (varDate > today) {
                    this.toastr.error(`Wrong date of birth choosen for ${el.memberDef}`, "Error");
                    valid = false;
                    break;
                } else if (el.date_of_birth.dd > monthdays) {
                    this.toastr.error("Invalid Date Of Birth", "Error");
                    valid = false;
                    break;
                }

                let memberAge = this.util.calcAgeinDecimal(dob);
                //alert(memberAge);

                if (memberAge > maxAge) {
                    maxAge = memberAge
                    eldestmemberGender = el.gender;
                }

                if (el.construct == '1a' && memberAge < 18) {
                    this.toastr.error(`${el.memberDef} age should be 18+`, "Error");
                    valid = false;
                    break;
                }

                if (el.construct == '1c' && ((memberAge > constant.childamaxAgelimit) || (memberAge < constant.childminAge))) {
                    this.toastr.error(`Child age should be between > 91 days and < 24 yrs`, "Error");
                    valid = false;
                    break;
                }

                if (el.relationship_with_user == 'father' || el.relationship_with_user == 'mother') {
                    let ageDiffwithproposer = memberAge - proposerAge;
                    if (ageDiffwithproposer < 18) {
                        this.toastr.error(`Incorrect Age of ${el.memberDef}`, "Error");
                        valid = false;
                        break;
                    }
                }
            }; // end of for loop

            this.eldestmemberAge = maxAge;
            this.eldestmemberGender = eldestmemberGender;

            localStorage.setItem('proposal_insured_member', JSON.stringify(this.proposal.insured_member));

            localStorage.setItem('step', '3');
            // step
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('medical details');

        }
        if (step == 4) {
            // console.log(this.medical_questions.existing_since.mm);

            let medical_details_present = localStorage.getItem('medical_details_present');
            if (medical_details_present) {

                if (medical_details_present == 'no') {
                    localStorage.setItem('medical_details_present', 'no')
                } else {
                    localStorage.setItem('medical_details_present', 'yes')
                }

            } else {
                localStorage.setItem('medical_details_present', 'no')
            }

            localStorage.setItem('step', '4');
            // step
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('kyc');

        }
        if (step == 5) {
            // console.log(this.medical_questions.existing_since.mm);
            // step
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            console.log('nominiee');

        }
        if (step == 6) {
            localStorage.setItem('proposal_nominee', JSON.stringify(this.proposal.nominee));
            localStorage.setItem('step', '6');
            let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
            let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
            let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
            let medical_details_present = localStorage.getItem('medical_details_present');
            let medical_details = JSON.parse(localStorage.getItem('medical_details'));
            if (proposal_Proposer) {
                this.proposal.first_name = proposal_Proposer.first_name;
                this.proposal.middle_name = proposal_Proposer.middle_name;
                this.proposal.last_name = proposal_Proposer.last_name;
                this.proposal.date_of_birth.dd = proposal_Proposer.date_of_birth.dd;
                this.proposal.date_of_birth.mm = proposal_Proposer.date_of_birth.mm;
                this.proposal.date_of_birth.yy = proposal_Proposer.date_of_birth.yy;
                this.proposal.marital_status = proposal_Proposer.marital_status;
                this.proposal.emailId = proposal_Proposer.emailId;
                this.proposal.contactNumber = proposal_Proposer.contactNumber;
                this.proposal.annual_income = proposal_Proposer.annual_income;
                this.proposal.address.house_no = proposal_Proposer.address.house_no;
                this.proposal.address.area = proposal_Proposer.address.area;
                this.proposal.address.city = proposal_Proposer.address.city;
                this.proposal.address.state = proposal_Proposer.address.state;
                this.proposal.address.pincode = proposal_Proposer.address.pincode;
            }
            if (proposal_insured_member) {
                this.proposal.insured_member = proposal_insured_member;
                console.log(this.proposal.insured_member);

            }
            if (proposal_nominee) {
                this.proposal.nominee = proposal_nominee;
            }
            if (medical_details_present) {
                if (medical_details_present == 'no') {

                    this.medical_details_status.status = false;
                    this.medical_details_status.value = 'NO';
                } else {
                    this.medical_details_status.status = true;
                    this.medical_details_status.value = 'YES';

                }
            }
            if (medical_details) {
                this.proposal.insured_member.medical_questions = medical_details;
            }
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.nominee_step = true;
            console.log('review');

        }

    }
    selectNomineeCity(e: { target: { value: any; }; }) {
        let cityObj = this.cityList2.find(c => c.hdfc_code == e.target.value);
        console.log(cityObj)
        if (cityObj) {
            this.proposal.nominee.cityName = cityObj['city_name']
        } else {
            this.proposal.nominee.city = '';
            alert('City Code doesnot exist');
            return;
        }
    }

    relationshipVal() {
        const relName = this.nomineeRelations.find(el => el.key == this.proposal.nominee.relationship_with_proposer)
        this.proposal.nominee.relation_value = relName.value
    }

    activityCapture(step: string) {
        if (step == 'proposer') {
            this.ngxLoader.start()
            // const url = `/proposalFormRoute/add_user_details/${this.userid}`;
            const occupation = this.occupation.find((occ) => occ.occupation_code == this.proposal.occupation);
            const postData = {
                "first_name": this.proposal.first_name,
                "last_name": this.proposal.last_name,
                "date_of_birth": this.proposal.date_of_birth.yy + '-' + this.proposal.date_of_birth.mm + '-' + this.proposal.date_of_birth.dd,
                "marital_status": this.proposal.marital_status,
                "gender": this.proposal.gender,
                "session_id": this.session_id,
                "height": this.proposal.height.ft + '.' + this.proposal.height.inch,
                "weight": this.proposal.weight,
                "occupation": (occupation) ? occupation.oc_id : '',
                "pan_card_no": this.proposal.pan,
                "emailId": this.proposal.emailId,
                "contactNumber": this.proposal.contactNumber,
                "annual_income": this.proposal.annual_income,
                "plan_id": this.plan_id,
                "sms_text": "",
                "address": {
                    "house_no": this.proposal.address.house_no,
                    "area": this.proposal.address.area,
                    "landmark": this.proposal.address.landmark,
                    "pincode": this.proposal.address.pincode,
                    "city_id": this.proposal.address.city_id,
                    "state_id": this.proposal.address.state_id,
                    "nationality": this.proposal.address.nationality
                }
            };
            // //console.log(postData)
            // this.commonService.post(url, postData).subscribe(res => {
            //     console.log(res)
            //     this.proposer_step = true;
            //     this.step1 = false;
            //     this.step2 = true;
            //     this.step3 = false;
            //     this.step4 = false;
            //     this.step6 = false;
            //     this.ngxLoader.stop();
            // }, err => {
            //     const errorMessage = err && err.message || 'Something goes wrong';
            //     this.toastr.error(errorMessage, 'Error');
            // })
            // console.log(res)
            this.proposer_step = true;
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step6 = false;
            this.ngxLoader.stop();
        } else if (step == 'insuredmembers') {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step2nextbtn = true;
            this.insured_member_step = true
            this.ngxLoader.stop()

        } else if (step == 'nominee') {
            this.ngxLoader.start()
            // const url = `/proposalFormRoute/add_nominee/${this.userid}?plan_id=${this.plan_id}&session_id=${this.session_id}&session_url=${this.fullURL + '&step=one'}`;
            const body = {
                "first_name": this.proposal.nominee.first_name,
                "last_name": this.proposal.nominee.last_name,
                "age": parseInt(this.proposal.nominee.age),
                "date_of_birth": 0,
                "sms_text": "",
                "relationship_with_proposer": this.proposal.nominee.relationship_with_proposer
            }
            if (parseInt(this.proposal.nominee.age) < 18) {
                body['appointee_data'] = [{
                    "first_name": this.proposal.appointee.appointeeName,
                    "last_name": this.proposal.appointee.appointeeName,
                    "age": (this.proposal.appointee.appointeeAge) ? parseInt(this.proposal.appointee.appointeeAge) : 0,
                    "relationship_with_proposer": this.proposal.appointee.appointeeRelationship
                }]
            } else {
                body['appointee_data'] = "";
            }
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.nominee_step = true;
            this.ngxLoader.stop();
        } else {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step6 = true;
            this.medical_history_step = true;
            this.ngxLoader.stop()
        }
    }

    checkPremium() {
        this.ngxLoader.start()
        if (this.step == '3') {
            let k = 0;
            this.actulaRel = this.actulaRel.map((el) => {
                if (el == 'son' || el == 'daughter') {
                    return `kid`
                } else {
                    return el
                }
            }).map(e => {
                if (e == 'kid') {
                    k++;
                    return `kid${k}`;
                } else {
                    return e;
                }
            })
        }
        console.log(this.actulaRel);
        const prem_type_disp = this.actulaRel.join("-");
        console.log(prem_type_disp);
        let url: string;
        this.eldestmemberAge = Math.round(this.eldestmemberAge);
        if (this.step == '3') {
            //Aditya Birla
            let gender: string;
            if (this.plandetails.premium.gender == "" || !this.plandetails.premium.gender) {
                gender = '';
            } else {
                gender = (this.eldestmemberGender == 'MALE' || this.eldestmemberGender == 'Male') ? '1' : '2';
            }
            let premtype = (this.construct == '1a' && prem_type_disp == 'self') ? 'individual' : prem_type_disp;
            url = `/premiumRoute/listByPlanCompanyPremDisplay?plan_id=${this.plan_id}&company_id=${this.company_id}&prem_type_disp=${premtype}&si_id=${this.plandetails.premium.si_id}&age=${this.eldestmemberAge}&gender=${gender}`;
        } else if (this.step == '2') {
            //Star
            let premtype = (this.construct == '1a' && prem_type_disp == 'self') ? '1a' : this.construct;
            url = `/premiumRoute/listByPlanCompanyPremDisplay?plan_id=${this.plan_id}&company_id=${this.company_id}&prem_type_disp=${premtype}&si_id=${this.plandetails.premium.si_id}&age=${this.eldestmemberAge}&gender=`
        } else if (this.step == '1') {
            //Religare
            let premtype = (this.construct == '1a' && prem_type_disp == 'self') ? 'individual' : this.construct;
            url = `/premiumRoute/listByPlanCompanyPremDisplay?plan_id=${this.plan_id}&company_id=${this.company_id}&prem_type_disp=${premtype}&si_id=${this.plandetails.premium.si_id}&age=${this.eldestmemberAge}&gender=`
        }

        if (url) {
            this.commonService.get(url).subscribe(res => {
                console.log("Check Premium : ", res);
                //alert('Premium will be changed..')
                this.ngxLoader.stop()
                if (res.data.length > 0) {
                    let finalPremium = res.data.filter((el: { premium_term: any; }) => el.premium_term == this.plandetails.premium_term);
                    let finalPremiumwithGST = Math.round(finalPremium[0]['premium_with_gst']);
                    let finalPremiumwithoutGST = Math.round(finalPremium[0]['premium_price']);
                    let finalGSTAmt = Math.round(finalPremium[0]['gst_amount']);
                    //console.log("Final Premium : ", finalPremium);
                    console.log("New Premium", finalPremiumwithGST);
                    console.log("Prev Premium", this.plandetails.OneyearFinalPremium);
                    if (finalPremiumwithGST != this.plandetails.OneyearFinalPremium) {
                        this.plandetails.OneyearFinalPremium = finalPremiumwithGST;
                        this.finalPremiumDisplay = this.util.currencyFormat(finalPremiumwithGST);
                        this.plandetails.premium.premium_price = this.util.currencyFormat(finalPremiumwithoutGST);
                        this.plandetails.premium.gst_amount = this.util.currencyFormat(finalGSTAmt);
                        this.step1 = false;
                        this.step2 = true;
                        this.step3 = false;
                        this.insured_member_step = true
                        var _self = this;
                        $.confirm({
                            title: 'Premium Change Alert!',
                            content: `Based on Relations & Age Your Final Premium will be Rs. ${this.finalPremiumDisplay}`,
                            buttons: {
                                proceed: function () {
                                    //$.alert('Confirmed!');
                                    console.log('proceed')
                                    localStorage.setItem('premium_id', finalPremium[0].premium_id);
                                    _self.step1 = false;
                                    _self.step2 = false;
                                    _self.step3 = true;
                                    _self.step2nextbtn = true;
                                    _self.insured_member_step = true
                                    _self.activityCapture('insuredmembers')
                                },
                                back: function () {
                                    _self._location.back();
                                }
                            }
                        });
                    } else {
                        this.activityCapture('insuredmembers')
                    }

                } else {
                    this.step2nextbtn = true;
                    this.step1 = false;
                    this.step2 = true;
                    this.step3 = false;
                    this.insured_member_step = true
                    this.util.errorDialog(`Based on the Insured Member relationship with proposer there is no suitable premium found.`, "Alert");
                }
            }, err => {

                const errorMessage = err && err.message || 'Something goes wrong';
                this.toastr.error(errorMessage, 'Error');
            })
        } else {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step2nextbtn = true;
            this.insured_member_step = true
            this.activityCapture('insuredmembers')
        }
    }

    back(step: number, flag?: boolean, index?: any) {
        if (flag) {
            this.previewFlag = flag
        }

        if (step == 1) {
            this.step1 = true;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = false;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('insures memeber');

        }
        if (step == 2) {
            this.step1 = false;
            this.step2 = true;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = false;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('insures memeber');

        }
        if (step == 3) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.step4 = false;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = false;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('medical details');

        }
        if (step == 4) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = true;
            this.step5 = false;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = false;
            this.nominee_step = false;
            console.log('kyc');

        }
        if (step == 5) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = true;
            this.step6 = false;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = false;
            console.log('nominiee');

        }
        if (step == 6) {
            this.step1 = false;
            this.step2 = false;
            this.step3 = false;
            this.step4 = false;
            this.step5 = false;
            this.step6 = true;
            this.proposer_step = true;
            this.insured_member_step = true;
            this.medical_history_step = true;
            this.kyc_step = true;
            this.nominee_step = true;
            console.log('review');

        }
        $("html, body").animate({ scrollTop: 0 }, 600);
    }

    selectDisease(e: { target: { checked: any; }; }, member: any, disease: { [x: string]: boolean; }) {
        if (e.target.checked) {
            disease['formvisible'] = true;
        } else {
            disease['formvisible'] = false;
        }
        console.log(this.proposal.insured_member)
    }

    medicalHistorySelect(e: { target: { checked: any; }; }, member: { has_ped: boolean; }) {
        if (e.target.checked) {
            member.has_ped = true;

            console.log('medicalHistorySelect + true');

        } else {
            console.log('medicalHistorySelect + false');
            member.has_ped = false;


        }
    }

    selectNomineeAge(e: { target: { value: number; }; }) {
        if (e.target.value < 18) {
            this.appointee = true;
        } else {
            this.appointee = false;
        }
    }

    savePolicy(IdTypeVal: string, IdTypeName?: string) {
        let body = {
            "user_id": this.userid,
            "session_id": localStorage.getItem("session_id"),
            "company_id": this.company_id,
            "plan_id": this.plan_id,
            "premium_id": this.premium_id,
            "proposal_id_name": IdTypeName,
            "proposal_id_value": IdTypeVal,
            "si_id": this.plandetails.coverage.si_id,
            "si_amount": parseInt(this.plandetails.coverage.sum_insured),
            "company_name": this.plandetails.company_master.company_name,
            "plan_name": this.plandetails.plan_name,
            "construct": this.plandetails.premium.premium_type_display_slug,
            "purchase_date": moment().format('YYYY-MM-DD'),
            "payment_status": "pending",
            "payment_status_reason": "pending",
            "transaction_id": "",
            "premium_amount": this.plandetails.OneyearFinalPremium,
            "response_data": {
                "amount": this.plandetails.premium.premium_price,
                "serviceTax": this.plandetails.premium.gst_amount,
                "totalPremium": this.plandetails.OneyearFinalPremium,
            }
        }
        console.log(body)
        const url = `/userPlanTransactionRoute/add`;
        this.commonService.post(url, body).subscribe(res => {
            console.log(res)
        }, err => {
            console.log("Error", err);

        })
    }

    declarationspopup(e: { target: { checked: any; }; }, template: TemplateRef<any>) {
        if (e.target.checked) {
            this.btndisabled = false;
            //this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
        } else {
            this.btndisabled = true;
        }
    }

    openTnc(template: any) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    }

    savenShare(template: TemplateRef<any>, section: string) {
        this.section = section;
        this.modalRef2 = this.modalService.show(template, { class: 'common-popup' });
    }

    nomineerelchange(e: { target: { value: any; }; }) {
        const rel = e.target.value;
        this.proposal.nominee.relationship_with_proposer = rel;
        this.proposal.nominee.relationship_with_proposer_value = rel;
    }

    submitSavenShare() {
        var regex = constant.emailvalidateregex;
        var regexcontactNo = constant.mobilevalidateregex;
        if (this.savenshareObj.emailId == '') {
            this.toastr.error("Please enter your valid email", "Error");
        } else if (!regex.test(this.savenshareObj.emailId)) {
            this.toastr.error("Please enter a valid email address", "Error");
        } else if (!regexcontactNo.test(this.savenshareObj.contactNumber)) {
            this.toastr.error('Please enter a valid 10 digit mobile number', "Error");
            return false;
        } else {
            this.snsBtn = 'Saving data...';
            this.snsdisabled = true;
            const userId = this.userid;
            if (this.section === 'proposer') {
                const url = `/proposalFormRoute/add_user_details/${userId}`;
                const occupation = this.occupation.find((occ) => occ.occupation_code == this.proposal.occupation);
                const postData = {
                    "session_id": this.session_id,
                    "session_url": this.fullURL + '&step=one',
                    "first_name": this.proposal.first_name,
                    "plan_id": this.plan_id,
                    "last_name": this.proposal.last_name,
                    "date_of_birth": this.proposal.date_of_birth.yy + '-' + this.proposal.date_of_birth.mm + '-' + this.proposal.date_of_birth.dd,
                    "marital_status": this.proposal.marital_status,
                    "gender": this.proposal.gender,
                    "height": this.proposal.height.ft + '.' + this.proposal.height.inch,
                    "annual_income": this.proposal.annual_income,
                    "weight": this.proposal.weight,
                    "occupation": (occupation) ? occupation.oc_id : '',
                    "pan_card_no": this.proposal.pan,
                    // "politically_exposed": "",
                    // "medical_practioner_id": "",
                    // "medical_council": "",
                    // "workplace_address": "",
                    "emailId": this.proposal.emailId,
                    "share_email_id": this.savenshareObj.emailId,
                    "email_text": this.fullURL + '&step=one',
                    "contactNumber": this.proposal.contactNumber,
                    "sms_text": this.fullURL + '&step=one',
                    "address": {
                        "house_no": this.proposal.address.house_no,
                        "area": this.proposal.address.area,
                        "landmark": this.proposal.address.landmark,
                        "pincode": this.proposal.address.pincode,
                        "city_id": this.proposal.address.city_id,
                        "state_id": this.proposal.address.state_id,
                        "nationality": this.proposal.address.nationality,
                    }
                };
                console.log(postData)
                // this.proposalpageSMS(this.fullURL + '&step=one');
                this.commonService.post(url, postData).subscribe(res => {
                    console.log(res)
                    this.toastr.success("Your data has been shared to EmailId and Mobile No provided", "Success");
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                    this.modalRef2.hide();
                }, err => {
                    const errorMessage = err && err.message || 'Something goes wrong';
                    this.toastr.error(errorMessage, 'Error');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                })
            } else if (this.section === 'insuredmembers') {
                // =================INSURED MEMBERS===========
                const url = `/proposalFormRoute/add_insured_members/${userId}?plan_id=${this.plan_id}&session_id=${this.session_id}`;
                const postBody = this.proposal.insured_member.map((el: { occupation: any; relationship_with_user: any; relationship_with_proposer: any; first_name: any; middle_name: any; last_name: any; date_of_birth: { yy: any; mm: any; dd: any; }; marital_status: any; gender: any; height: { ft: string; inch: string; }; weight: any; lifestyle: any[]; }) => {
                    const occupation = this.occupation.find((occ) => occ.occupation_code == el.occupation);
                    return {
                        "session_url": this.fullURL + '&step=two',
                        "sms_text": this.fullURL + '&step=two',
                        "sms_contact_no": this.savenshareObj.contactNumber,
                        "share_email_id": this.savenshareObj.emailId,
                        "relationship_with_user": el.relationship_with_user,
                        "relation_code": el.relationship_with_proposer,
                        "first_name": el.first_name,
                        "middle_name": el.middle_name,
                        "last_name": el.last_name,
                        "date_of_birth": `${el.date_of_birth.yy}-${el.date_of_birth.mm}-${el.date_of_birth.dd}`,
                        "marital_status": el.marital_status,
                        "gender": el.gender,
                        "height": el.height.ft + '.' + el.height.inch,
                        "weight": el.weight,
                        "aadhar_number": "",
                        "pan_card_no": "",
                        "contactNumber": "",
                        "emailId": "",
                        "medical_qa": [],
                        "occupation_id": (occupation) ? occupation.oc_id : '',
                        "medical_history": [],
                        "lifestyle": el.lifestyle.filter((el: { checked: any; }) => el.checked).map((v: { lifestyle_id: any; lifestyle_name: any; numberOfYears: any; consumes: any; }) => {
                            return {
                                lifestyle_id: v.lifestyle_id,
                                lifestyle_type: v.lifestyle_name,
                                number_of_years: v.numberOfYears,
                                consumption_per_day: v.consumes
                            }
                        })
                    }
                })
                console.log("Insured member : ", postBody)

                // this.proposalpageSMS(this.fullURL + '&step=two');
                this.commonService.post(url, postBody).subscribe(res => {
                    console.log(res)
                    this.toastr.success("Your data has been shared to EmailId and Mobile No provided", 'Success');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                    this.modalRef2.hide();
                }, err => {
                    const errorMessage = err && err.message || 'Something goes wrong';
                    this.toastr.error(errorMessage, 'Error');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                })
            } else if (this.section === 'nominee') {
                const url = `/proposalFormRoute/add_nominee/${userId}?plan_id=${this.plan_id}&session_id=${this.session_id}`;
                const body = {
                    "session_url": this.fullURL + '&step=three',
                    "sms_text": this.fullURL + '&step=three',
                    "sms_contact_no": this.savenshareObj.contactNumber,
                    "share_email_id": this.savenshareObj.emailId,
                    "first_name": this.proposal.nominee.first_name,
                    "last_name": this.proposal.nominee.last_name,
                    "age": parseInt(this.proposal.nominee.age),
                    "date_of_birth": 0,
                    "relationship_with_proposer": this.proposal.nominee.relationship_with_proposer
                }
                if (parseInt(this.proposal.nominee.age) < 18) {
                    body['appointee_data'] = [{
                        "first_name": this.proposal.appointee.appointeeName,
                        "last_name": this.proposal.appointee.appointeeName,
                        "age": (this.proposal.appointee.appointeeAge) ? parseInt(this.proposal.appointee.appointeeAge) : 0,
                        "relationship_with_proposer": this.proposal.appointee.appointeeRelationship
                    }]
                } else {
                    body['appointee_data'] = "";
                }
                // this.proposalpageSMS(this.fullURL + '&step=three');
                this.commonService.post(url, body).subscribe(res => {
                    console.log(res)
                    this.toastr.success("Your data has been shared to EmailId and Mobile No provided", 'Success');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                    this.modalRef2.hide();
                }, err => {
                    const errorMessage = err && err.message || 'Something goes wrong';
                    this.toastr.error(errorMessage, 'Error');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                })
            } else if (this.section === 'medical') {
                //=======Medical History-----
                const url = `/proposalFormRoute/add_insured_members/${userId}?plan_id=${this.plan_id}&session_id=${this.session_id}`;
                const postBody = this.proposal.insured_member.map((el: { occupation: any; relationship_with_user: any; first_name: any; middle_name: any; date_of_birth: { yy: any; mm: any; dd: any; }; marital_status: any; gender: any; height: { ft: string; inch: string; }; weight: any; has_ped: any; medical_history: any[]; medical_questions: any[]; lifestyle: any[]; }) => {
                    const occupation = this.occupation.find((occ) => occ.occupation_code == el.occupation);
                    return {
                        "session_url": this.fullURL + '&step=four',
                        "sms_text": this.fullURL + '&step=four',
                        "sms_contact_no": this.savenshareObj.contactNumber,
                        "share_email_id": this.savenshareObj.emailId,
                        "relationship_with_user": el.relationship_with_user,
                        "first_name": el.first_name,
                        "middle_name": el.middle_name,
                        "last_name": el.first_name,
                        "date_of_birth": `${el.date_of_birth.yy}-${el.date_of_birth.mm}-${el.date_of_birth.dd}`,
                        "marital_status": el.marital_status,
                        "gender": el.gender,
                        "height": el.height.ft + '.' + el.height.inch,
                        "weight": el.weight,
                        "aadhar_number": "",
                        "pan_card_no": "",
                        "contactNumber": "",
                        "emailId": "",
                        "occupation_id": (occupation) ? occupation.oc_id : '',
                        "has_ped": el.has_ped,
                        "medical_history": el.medical_history.filter((el: { formvisible: any; }) => el.formvisible).map((m: { medical_history_id: any; current_status: any; symptom_start_date: { mm: string; yy: string; }; health_declined: any; }) => {
                            return {
                                medical_history_id: m.medical_history_id,
                                recovery_status: m.current_status,
                                symptom_start_date: (m.symptom_start_date.mm && m.symptom_start_date.yy) ? m.symptom_start_date.yy + '-' + m.symptom_start_date.mm + '-01' : 0,
                                health_declined: m.health_declined
                            }
                        }),
                        medical_qa: el.medical_questions.map((qus: { [x: string]: string | number; pqm_id: any; question_code: any; }) => {
                            return {
                                plan_ques_map_id: qus.pqm_id,
                                question_code: qus.question_code,
                                answer: qus[qus['question_code']]
                            }
                        }),
                        "lifestyle": el.lifestyle.filter((el: { checked: any; }) => el.checked).map((v: { lifestyle_id: any; consumes: any; }) => {
                            return {
                                lifestyle_id: v.lifestyle_id,
                                consumption_per_day: v.consumes
                            }
                        })
                    }
                })
                console.log("Medical History : ", postBody);
                // this.proposalpageSMS(this.fullURL + '&step=four');
                this.commonService.post(url, postBody).subscribe(res => {
                    console.log(res)
                    this.toastr.success("Your data has been shared to EmailId and Mobile No provided", "Success");
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                    this.modalRef2.hide();
                }, err => {
                    const errorMessage = err && err.message || 'Something goes wrong';
                    this.toastr.error(errorMessage, 'Error');
                    this.snsBtn = 'Save & Share';
                    this.snsdisabled = false;
                })
            } else if (this.section === 'buylater') {
                // this.previewSMS()
                // this.buyLater()
            }
        }
    }


    calculateAgeofInsuredMember(e: { target: { value: string; }; }, index: string | number) {
        var d = new Date();
        let currentYear = d.getFullYear();
        let diff = currentYear - parseInt(e.target.value);
        if (diff < 18) {
            this.proposal.insured_member[index]['marital_status_flag'] = false;
            this.proposal.insured_member[index]['marital_status'] = 'Single';
        } else {
            this.proposal.insured_member[index]['marital_status_flag'] = true;
        }
    }

    CheckBMI(height: number, weight: number) {
        let BMI = 0;
        let calcheight = (height / 100) * (height / 100);
        BMI = weight / calcheight;
        return BMI;
    }

    getstateList() {
        // const url = `/cityRoute/liststate/1/0/1/?search_key=`;
        // this.commonService.get(url).subscribe(res => {
        this.stateList = [
            {
                state_name: "Maharashtra",
                state_id: "21",
            }
        ];
        this.stateName = this.stateList.filter((el: { state_id: any; }) => {
            return el.state_id === this.proposal.address.state_id
        })[0]['state_name'];
        if (this.stateName) {
            this.proposal.address.state = this.stateName
        } else {
            alert("State Name not found")
        }
        console.log("State Name : ", this.proposal.address.state);
        // }, err => {
        //     const errorMessage = err && err.message || 'Something goes wrong';
        //     this.toastr.error(errorMessage, 'Error');
        // })
    }

    // call prposal api 
    ProposalCreationForHealth_api(btnText) {
        //    alert(btnText)

        const policyStartDate = this.calculatePolicyStartDate();
        const policyEndDate = this.calculatePolicyEndDate(policyStartDate, 1);

        let proposal_Proposer = JSON.parse(localStorage.getItem('proposal_Proposer'));
        let proposal_insured_member = JSON.parse(localStorage.getItem('proposal_insured_member'));
        let proposal_nominee = JSON.parse(localStorage.getItem('proposal_nominee'));
        let medical_details_present = localStorage.getItem('medical_details_present');
        let medical_details = JSON.parse(localStorage.getItem('medical_details'));
        if (proposal_insured_member) {
            this.proposal.insured_member = proposal_insured_member;
            console.log(this.proposal.insured_member);

        }
        if (proposal_nominee) {
            this.proposal.nominee = proposal_nominee;
        }

        let medical_detailsData = []
        if (medical_details_present) {
            if (medical_details_present == 'no') {
                medical_detailsData = [];

            } else {
                if (medical_details) {
                    medical_detailsData = medical_details;
                }


            }
        }

        let marital_status = proposal_Proposer.marital_status;
        let marital_status_value = '';

        switch (marital_status) {
            case 'Married':
                marital_status_value = '1951';
                break;
            case 'Single':
                marital_status_value = '1952';
                break;
            case 'Divorcee':
                marital_status_value = '1953';
                break;
            case 'Widow':
                marital_status_value = '1954';
                break;
            case 'Widower':
                marital_status_value = '1994';
                break;
            default:
                marital_status_value = '';
                break;
        }
        let MaritalStatusID_status = proposal_insured_member[0].marital_status;
        let MaritalStatusID_value = '';

        switch (MaritalStatusID_status) {
            case 'Married':
                MaritalStatusID_value = '1951';
                break;
            case 'Single':
                MaritalStatusID_value = '1952';
                break;
            case 'Divorcee':
                MaritalStatusID_value = '1953';
                break;
            case 'Widow':
                MaritalStatusID_value = '1954';
                break;
            case 'Widower':
                MaritalStatusID_value = '1994';
                break;
            default:
                MaritalStatusID_value = '';
                break;
        }

        let RelationshipWithProposerID = proposal_insured_member[0].relationship_with_user;
        let RelationshipWithProposer = '';

        switch (RelationshipWithProposerID) {
            case '345':
                RelationshipWithProposer = 'Self';
                break;
            case '320':
                RelationshipWithProposer = 'Spouse';
                break;
            case '321':
                RelationshipWithProposer = 'Son';
                break;
            case '322':
                RelationshipWithProposer = 'Daughter';
                break;
            case '2059':
                RelationshipWithProposer = 'Son In Law';
                break;
            case '2060':
                RelationshipWithProposer = 'Daughter In Law';
                break;
            case '319':
                RelationshipWithProposer = 'Father';
                break;
            case '1255':
                RelationshipWithProposer = 'Mother';
                break;
            case '2058':
                RelationshipWithProposer = 'Father In Law';
                break;
            case '2057':
                RelationshipWithProposer = 'Mother In Law';
                break;
            case '2063':
                RelationshipWithProposer = 'Grand Father';
                break;
            case '2064':
                RelationshipWithProposer = 'Grand Mother';
                break;
            case '2061':
                RelationshipWithProposer = 'Grand Son';
                break;
            case '2062':
                RelationshipWithProposer = 'Grand Daughter';
                break;
            case '324':
                RelationshipWithProposer = 'Brother';
                break;
            case '323':
                RelationshipWithProposer = 'Sister';
                break;
            case '2671':
                RelationshipWithProposer = 'Brother In Law';
                break;
            case '2672':
                RelationshipWithProposer = 'Sister In Law';
                break;
            case '3095':
                RelationshipWithProposer = 'Nephew';
                break;
            case '3759':
                RelationshipWithProposer = 'Niece';
                break;
            default:
                RelationshipWithProposer = '';
                break;
        }



        // // Function to convert existing_since to dd/mm/yy format
        // function convertDateFormat(dateObject) {
        //     const dd = "01"; // Assuming day is always 01
        //     const mm = dateObject.mm;
        //     const yy = dateObject.yy;

        //     return `${dd}/${mm}/${yy}`;
        // }

        // // Loop through diseases and update existing_since format
        // for (const disease of medical_detailsData) {
        //     disease.existing_since = convertDateFormat(disease.existing_since);
        // }

        // console.log(medical_detailsData);

        const convertedConditions = medical_detailsData.map(condition => {
            const sufferingSince = `${condition.existing_since.dd || '01'}/${condition.existing_since.mm || '01'}/${condition.existing_since.yy}`;
            return { DiseaseID: condition.DiseaseID, SufferingSince: sufferingSince };
        });

        console.log(convertedConditions);


        if (btnText == 'Next') {

            let params =
            {
                'ClientDetails': {
                    'ClientTypeID': '0',
                    'DOB': `${proposal_Proposer.date_of_birth.dd}/${proposal_Proposer.date_of_birth.mm}/${proposal_Proposer.date_of_birth.yy}`,
                    'Email': proposal_Proposer.emailId,
                    'FirstName': proposal_Proposer.first_name,
                    'LastName': proposal_Proposer.last_name,
                    'MiddleName': proposal_Proposer.middle_name,
                    'MaritalStatusID': marital_status_value,
                    'Gender': proposal_Proposer.gender == 'MALE' ? "0" : "1",
                    'Nationality': 'Indian',
                    'OccupationID': proposal_Proposer.occupation,
                    'Salutation': 'Mr.',
                    'PassportNo': null,
                    'AnnualIncome': proposal_Proposer.annual_income,
                    'StateID': '21',
                    'AadhaarNo': null,
                    'RegisteredUnderGST': null,
                    'RelatedParty': null,
                    'GSTIN': null,
                    'ClientAddress': {
                        'CommunicationAddress': {
                            'Aadhaar': null,
                            'Address1': proposal_Proposer.address.house_no,
                            'Address2': proposal_Proposer.address.landmark,
                            'Address3': null,
                            'CityID': '244887',
                            'Country': '1',
                            'DistrictID': '336',
                            'Email': proposal_Proposer.emailId,
                            'Fax': null,
                            'MobileNo': proposal_Proposer.contactNumber,
                            'NearestLandmark': null,
                            'PanNo': null,
                            'PhoneNo': proposal_Proposer.contactNumber,
                            'Pincode': proposal_Proposer.address.pincode,
                            'AreaID': '45583',
                            'StateID': '21',
                            'City': '',
                            'District': '',
                            'Area': '',
                            'State': '',
                            'IsNomineeSameasCommAddr': 'true'
                        },
                        'PermanentAddress': {
                            'Address': {
                                'Aadhaar': null,
                                'Address1': 'adddresss1 addddresss2',
                                'Address2': '.',
                                'Address3': null,
                                'CityID': '244887',
                                'Country': '1',
                                'DistrictID': '336',
                                'Email': 'latam@policybazaar.com',
                                'Fax': null,
                                'MobileNo': '8765434567',
                                'NearestLandmark': null,
                                'PanNo': null,
                                'PhoneNo': '8765434567',
                                'Pincode': '400601',
                                'AreaID': '45583',
                                'StateID': '21',
                                'City': '',
                                'District': '',
                                'Area': '',
                                'State': '',
                                'IsNomineeSameasCommAddr': 'true'
                            },
                            'IsPermanentSameasCommAddr': 'true'
                        }
                    },
                    'MobileNo': proposal_Proposer.contactNumber
                },
                'InsuredDetailsList': [
                    {
                        'RelationshipWithProposerID': RelationshipWithProposerID,
                        'RelationshipWithProposer': RelationshipWithProposer,
                        'IsAnyPreExistingIllness': 'false',
                        'Salutation': 'Mr.',
                        'FirstName': proposal_insured_member[0].first_name,
                        'LastName': proposal_insured_member[0].last_name,
                        'MiddleName': proposal_insured_member[0].middle_name,
                        'Gender': proposal_insured_member[0].gender == 'MALE' ? "0" : "1",
                        'Age': ProposalComponent.calculateAge(`${proposal_insured_member[0].date_of_birth.dd}/${proposal_insured_member[0].date_of_birth.mm}/${proposal_insured_member[0].date_of_birth.yy}`),
                        'DOB': `${proposal_insured_member[0].date_of_birth.dd}/${proposal_insured_member[0].date_of_birth.mm}/${proposal_insured_member[0].date_of_birth.yy}`,
                        'MaritalStatusID': MaritalStatusID_value,
                        'MaritalStatus': MaritalStatusID_status,
                        'OccupationID': proposal_insured_member[0].occupation,
                        'Occupation': null,
                        'UHID': null,
                        'UHIDCreatedDate': null,
                        'Height': ProposalComponent.convertHeightToCm(proposal_insured_member[0].height.ft, proposal_insured_member[0].height.inch),
                        'Weight': proposal_insured_member[0].weight,
                        'SpecialConditions': '',
                        'PreExistingDisease': {
                            'IsExistingIllness': medical_details_present == 'no' ? 'false' : 'true',
                            'DiseaseList': convertedConditions,
                            'IsInsuredConsumetobacco': null,
                            'HasAnyPreClaimOnInsured': null,
                            'DetailsOfPreClaimOnInsured': null,
                            'HasAnyPreHealthInsuranceCancelled': null,
                            'DetailsOfPrevInsuranceCancelled': null
                        },
                        'InsureQuestionsList': [

                        ],
                        'OtherInsuranceList': [
                            {
                                'PreviousInsuraceCompName': null,
                                'PreviousPolNo': null,
                                'PreviousPolStartDate': null,
                                'PreviousPolEndDate': null,
                                'CoverTypeID': null,
                                'SumInsured': null,
                                'AccumulatedCumulativeBonus': null
                            }
                        ],
                        'SumInsured': '',
                        'IsAnyPhysicalDefect': null,
                        'PhysicalDefectDetails': null,
                        'ISPersonEarningMember': null,
                        'TotalCapitalSI': null,
                        'Hasanyclaimwithcurrentpreviousinsurer': null,
                        'IsAnyDeclinedPolicy': null,
                        'IsAnyotherhlthinspolicywiththeCompany': null,
                        'OtherInformation': null,
                        'Discount': null,
                        'Loading': null,
                        'IsAnyInsuredClaimed': null,
                        'IsPrevInsuranceCancelled': null,
                        'IsInsuredcoveredInHltPolicy': null,
                        'NationalityId': null,
                        'InsuredClaimDetails': null,
                        'IsFitnessDeclaration': null,
                        'IsInsuredConsumetobacco': null,
                        'IsExistingIllness': null,
                        'DoesInsuredSmokeOrConsumeAlchohol': null,
                        'IsIllnessCancer': null,
                        'IsIllnessOrganTransplant': null,
                        'IsIllnessMultipleScelorsis': null,
                        'IsIllnessBurns': null,
                        'IsIllnessGraft': null,
                        'IsIllnessHeartValve': null,
                        'IsIllnessComa': null,
                        'IsIllnessQuadriplegia': null,
                        'IsIllnessBlind': null,
                        'IsIllnessRenal': null,
                        'IllnessCancerNames': null,
                        'IllnessOrganTransplantNames': null,
                        'IllnessMultipleScelorsisNames': null,
                        'IllnessBurnsNames': null,
                        'IllnessGraftNames': null,
                        'IllnessHeartValveNames': null,
                        'IllnessComaNames': null,
                        'IllnessQuadriplegiaNames': null,
                        'IllnessBlindNames': null,
                        'IllnessRenalNames': null,
                        'SplitApplicable': null,
                        'IsMemberRenewed': null,
                        'PreviousCumulativeBonusrate': null,
                        'PreviousCumulativeBonus': null,
                        'CumulativeBonus': null,
                        'CumulativeBonusrate': null,
                        'NewlyAddedMember': null,
                        'CurrentYearNCD': null,
                        'ApplicableSublimitforPortability': null,
                        'IsInsuredDiagnosedEarlier': null,
                        'EditGridRow': null
                    }
                ],
                'Policy': {
                    'Tenure': 1,
                    'PolicyStartDate': policyStartDate,
                    'PolicyEndDate': policyEndDate,
                    'Branch_Code': '9202',
                    'BusinessTypeID': '1',
                    'ProductCode': '2868',
                    'ExternalSystemID': '1',
                    'POSType': null,
                    'POSAadhaarNumber': null,
                    'POSPANNumber': null,
                    'IsQuickQuote': false
                },
                'RiskDetails': {
                    'PlanID': 82,
                    'CategoryID': '0',
                    'DOBofSeniorMost': `${proposal_insured_member[0].date_of_birth.dd}/${proposal_insured_member[0].date_of_birth.mm}/${proposal_insured_member[0].date_of_birth.yy}`,
                    'MemberCount': 1,
                    'Age': ProposalComponent.calculateAge(`${proposal_insured_member[0].date_of_birth.dd}/${proposal_insured_member[0].date_of_birth.mm}/${proposal_insured_member[0].date_of_birth.yy}`),
                    'CoverTypeID': '1',
                    'SumInsured': '500000',
                    'PaymentBy': 'Proposer',
                    'Tenure': 1,
                    'InstallmentTypeID': null,
                    'NameOfPhysician': null,
                    'PhysicianContactNo': null,
                    'PhysicianEmail': null,
                    'NoofInstallments': null,
                    'HealtExtension': {
                        'Roomrenttype': null,
                        'Voluntarydeductiblelimit': null,
                        'Reductioninwaitingperiodlimit': null,
                        'DCBLimit': null,
                        'lstAddonDetails': [
                            {
                                'AddOnName': 'AD01',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD02',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD03',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD04',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD05',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD06',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD08',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD09',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD10',
                                'IsSelected': false
                            },
                            {
                                'AddOnName': 'AD11',
                                'IsSelected': false
                            }
                        ]
                    },
                    'MultipleSI': 'No',
                    'PlanwiseAddOn': 'false',
                    'AddOnPlansType': 'None',
                    'Bundling': 'false',
                    'UpgradepremiumByZone': 'false',
                    'Zone': null,
                    'DeductibleSI': '0',
                    'BundleSumInsured': null,
                    'CoverType': 'Individual',
                    'NoofMembersAbove21': null,
                    'NoofMembersBelow21': 0
                },
                'NomineeDetails': {
                    'Salutation': 'Mr.',
                    'FirstName': proposal_nominee.first_name,
                    'MiddleName': '',
                    'LastName': proposal_nominee.last_name,
                    'DOB': '01/01/1998',
                    'ApoointeeNomineeMobileNoHI': '',
                    'AppointeeDOB': '01/01/1998',
                    'NomineeRelationshipID': '1986',
                    'NomineeRelationshipOther': null,
                    'GuardianName': null,
                    'RelationshipwithProposerID': '320',
                    'RelationshipwithProposer': null,
                    'NomineeAddress': {
                        'Aadhaar': null,
                        'Address1': proposal_Proposer.address.house_no,
                        'Address2': proposal_Proposer.address.landmark,
                        'Address3': null,
                        'CityID': '244887',
                        'Country': '1',
                        'DistrictID': '336',
                        'Email': proposal_Proposer.emailId,
                        'Fax': null,
                        'MobileNo': proposal_Proposer.contactNumber,
                        'NearestLandmark': null,
                        'PanNo': null,
                        'PhoneNo': proposal_Proposer.contactNumber,
                        'Pincode': proposal_Proposer.address.pincode,
                        'AreaID': '45583',
                        'StateID': '21',
                        'City': '',
                        'District': '',
                        'Area': '',
                        'State': '',
                        'IsNomineeSameasCommAddr': 'true'
                    },
                    'AppointeeName': null
                },
                'PreviousInsuranceDetails': {
                    'PrevInsuranceID': null,
                    'PrevYearPolicyNo': null,
                    'PrevYearPolicyStartDate': null,
                    'PrevYearPolicyEndDate': null,
                    'PreYearNCD': null,
                    'prevYearTenure': null,
                    'PrevYearSI': null,
                    'PrevInsurerBranchID': null,
                    'PrevInsurerContactNo': null,
                    'PrevInsurerEmailID': null,
                    'PrevInsurerAddress': null,
                    'PrevInsurerBranchPincode': null,
                    'PrevPolicyType': null
                },
                'PolicyDiscount': {
                    'IsAnyEmployeeOfRelianceADAGroup': 'false',
                    'EmployeeCode': '',
                    'IsRGIHealthPolicyHolder': 'false',
                    'IsRGIRetailPolicyHolder': 'false',
                    'RGIHealthPolicyNo': null,
                    'RGIRetailPolicyNo': null,
                    'IsReferredByEmployee': 'false',
                    'IsOtherInsuranceApplicable': 'false'
                },
                'CoverDetails': null,
                'ClaimStatus': 'false',
                'NoofSplits': null,
                'Issplitcase': null,
                'UserID': '100002API',
                'SourceSystemID': '100002API',
                'AuthToken': 'Pass@123',
                'TraceID': null,
                'IsQuickquote': 'false'
            }


            this.ProposalService.ProposalCreationForHealthToAPI(params).subscribe(
                (response) => {
                    console.log('API Response:', response);
                    if (response.Policy.ProposalNo !== null) {
                        this.btnText = "Buy Now"
                        localStorage.setItem('ProposalNo',response.Policy.ProposalNo)
                        localStorage.setItem('FinalPremium',response.Premium.FinalPremium)
                        localStorage.setItem('UserID',response.UserID)
                    }
                },
                (error) => {
                    console.error('API Error:', error);
                    // Handle the error as needed
                }
            );


            // this.btnText = "Buy Now" 
        } else {
            let ProposalNo = localStorage.getItem('ProposalNo');
            let FinalPremium = localStorage.getItem('FinalPremium');
            let UserID = localStorage.getItem('UserID');
            let PAN_Verified = JSON.parse(localStorage.getItem('PAN_Verified'));
            const url = `https://rgipartners.reliancegeneral.co.in/PaymentIntegration/PaymentIntegration?ProposalNo=${ProposalNo}&userID=${UserID}&ProposalAmount=${FinalPremium}&PaymentType=1&Responseurl=http://localhost:4200/home&CKYC=${PAN_Verified.CKYC}&IsDocumentUpload=${PAN_Verified.IsDocumentUpload}&PanNo=${PAN_Verified.PanNo}&IsForm60=${PAN_Verified.IsForm60}`;

            localStorage.setItem('url',url);

            window.location.href = url;
        }

    }

    private calculatePolicyStartDate(): string {
        const currentDate = new Date();
        return `${(currentDate.getDate() + 1).toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;
    }

    private calculatePolicyEndDate(startDate: string, tenure: number): string {
        console.log(startDate); // 26/12/2023

        // Assuming startDate is in 'DD/MM/YYYY' format
        const [day, month, year] = startDate.split('/').map(Number);

        const startDateObj = new Date(year, month - 1, day - 1); // Month is 0-indexed
        const endDateObj = new Date(startDateObj);
        endDateObj.setFullYear(startDateObj.getFullYear() + tenure);

        const endDay = endDateObj.getDate().toString().padStart(2, '0');
        const endMonth = (endDateObj.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const endYear = endDateObj.getFullYear().toString();

        return `${endDay}/${endMonth}/${endYear}`;
    }

    static calculateAge(birthdate: string): number {
        const currentDate = new Date();
        const birthDate = new Date(birthdate);

        // Calculate the age
        let age = currentDate.getFullYear() - birthDate.getFullYear();

        // Check if the birthday has occurred this year
        if (
            currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
        ) {
            age--;
        }

        return age;
    }

    static convertHeightToCm(feet: number, inches: number): number {
        // 1 foot = 30.48 cm
        const heightInCm = feet * 30.48;

        // 1 inch = 2.54 cm
        const additionalCm = inches * 2.54;

        // Total height in centimeters
        const totalHeightInCm = heightInCm + additionalCm;

        return Math.round(totalHeightInCm);
    }


}
