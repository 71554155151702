import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  modalRef: BsModalRef;
  leader: any = {};
  leaders = [];
  directors = [];
  members = [{
    name: 'Harshit Jain',
    pic: './assets/images/leaders/harshit.png',
    cat: 'A',
    intro:'With deep-rooted knowledge, he is our true Principal',
    designation: 'CEO & Principal Officer',
    desc: `If Leadership can be defined by a certain team member’s capability to transform visions into reality with deep-rooted knowledge on the subject, then our ‘Principal’ PO & CEO, Harshit Jain, can stand as the living definition of a ‘leader’. Our Principal is literally the Principal Officer of Trinity Reinsurance Brokers Limited, as that explains the inception of that name. Having completed his fellowship from Insurance institute of India, an MBA in Insurance and 15 years of handling the direct & retail segments of business, matters of Insurance is now genuinely his ‘Beyein hat ka khel!’`
  }, {
    name: 'Akhilesh Jain',
    pic: './assets/images/leaders/akhilesh.png',
    cat: 'A',
    intro:'With a deep-dug experience he is, the ‘Iron-Man’ of our crew',
    designation: 'Vice Chairman',
    desc: `With his 15 years of experience in both the insurance and re-insurance industries, we need not
        explain the reason behind his name. With a deep-dug experience in the managing sector of
        Insurance & Brokerage business, he is our know-it-all person of the crew. Having completed his
        postgrads in Management & his studies on International Business from the University of
        Birmingham, England, it’s his technical and marketing expertise that literally makes him the
        trademarked ‘Iron-Man’ of our crew.`
  }];

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    this.leaders = this.members.filter(e=>e.cat == 'B');
    this.directors = this.members.filter(e=>e.cat == 'A');
    $("html, body").animate({ scrollTop: 0 }, 600);

    setTimeout(() => {
      $('.carousel-leaders').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        autoplay: false,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 2
          }
        }
      });

      $('.carousel-directors').owlCarousel({
        loop: true,
        autoplay: false,
        margin: 10,
        nav: true,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 2
          }
        }
      })
    }, 100);

    
    // $('.carousel-awards').owlCarousel({
    //   loop: true,
    //   margin: 20,
    //   autoplay: true,
    //   nav: true,
    //   dots: false,
    //   responsive: {
    //     0: {
    //       items: 2
    //     },
    //     600: {
    //       items: 2
    //     },
    //     1000: {
    //       items: 4
    //     }
    //   }
    // })
   

  }

  openModal(template: TemplateRef<any>, leader) {
    this.leader = leader;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg common-popup' });
  }

}
