import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CalculatePremiumforHealthNew {

  individual: any = {
    type: 'I',
    gender: '1',
    age: '',
    adult_number: 1,
    policyTenure: 1,
    child_number: 0,
    userName: '',
    emailId: '',
    city_id: '',
    contactNumber: '',
    ped: '0',
    pincode: '400086',
    diseaseSelection:[
      {
        'DiseaseID':'14',
        'SufferingSince':'29/12/2018'
      }
    ]
  };



  constructor(private http: HttpClient) { }

  postDataToAPI(): Observable<any> {
    const url = `/reliance/api/Services/PremiumCalulationForHealth`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    

    const individualFrm = JSON.parse(localStorage.getItem('userData1'));
    if (individualFrm) {
      this.individual = individualFrm
      
    }

    const policyStartDate = this.calculatePolicyStartDate();
    const policyEndDate = this.calculatePolicyEndDate(policyStartDate, this.individual.policyTenure || 1);

    if (this.individual.type === 'I') {
      const params = {
        'ClientDetails': {
          'ClientTypeID': '0',
          'DOB': CalculatePremiumforHealthNew.calculateBirthdate(this.individual.age),
          'Email': this.individual.emailId,
          'FirstName': 'testtst',
          'LastName': 'user',
          'MiddleName': '',
          'MaritalStatusID': '1952',
          'Gender': this.individual.gender,
          'Nationality': 'Indian',
          'OccupationID': '2056',
          'Salutation': 'Mr.',
          'PassportNo': null,
          'AnnualIncome': null,
          'StateID': '21',
          'AadhaarNo': null,
          'RegisteredUnderGST': null,
          'RelatedParty': null,
          'GSTIN': null,
          'ClientAddress': {
            'CommunicationAddress': {
              'Aadhaar': null,
              'Address1': '.',
              'Address2': '.',
              'Address3': null,
              'CityID': '244887',
              'Country': '1',
              'DistrictID': '336',
              'Email': this.individual.emailId,
              'Fax': null,
              'MobileNo': this.individual.contactNumber,
              'NearestLandmark': null,
              'PanNo': null,
              'PhoneNo': this.individual.contactNumber,
              'Pincode': this.individual.pincode,
              'StateID': '21',
              'City': '',
              'District': '',
              'Area': '',
              'State': '',
              'IsNomineeSameasCommAddr': 'true'
            },
            'PermanentAddress': {
              'Address': {
                'Aadhaar': null,
                'Address1': '.',
                'Address2': '.',
                'Address3': null,
                'CityID': '244887',
                'Country': '1',
                'DistrictID': '336',
                'Email': this.individual.emailId,
                'Fax': null,
                'MobileNo': this.individual.contactNumber,
                'NearestLandmark': null,
                'PanNo': null,
                'PhoneNo': this.individual.contactNumber,
                'Pincode': this.individual.pincode,
                'StateID': '21',
                'City': '',
                'District': '',
                'Area': '',
                'State': '',
                'IsNomineeSameasCommAddr': 'true'
              },
              'IsPermanentSameasCommAddr': true
            }
          },
          'MobileNo': this.individual.contactNumber
        },
        'InsuredDetailsList': [
          {
            'RelationshipWithProposerID': '345',
            'IsAnyPreExistingIllness': 'false',
            'Salutation': 'Mr.',
            'FirstName': 'spouse',
            'LastName': 'tstst',
            'MiddleName': null,
            'Gender': '0',
            'Age': this.individual.age,
            'DOB': CalculatePremiumforHealthNew.calculateBirthdate(this.individual.age),
            'MaritalStatusID': '1952',
            'MaritalStatus': 'Single',
            'OccupationID': '2056',
            'Occupation': null,
            'UHID': null,
            'UHIDCreatedDate': null,
            // 'Height': '68',
            // 'Weight': '67',
            'SpecialConditions': '',
            'PreExistingDisease': {
              'IsExistingIllness': this.individual.diseaseSelection.length === 0 ? false :true ,
              'DiseaseList': this.individual.diseaseSelection,
              'IsInsuredConsumetobacco': null,
              'HasAnyPreClaimOnInsured': null,
              'DetailsOfPreClaimOnInsured': null,
              'HasAnyPreHealthInsuranceCancelled': null,
              'DetailsOfPrevInsuranceCancelled': null
            },
            'InsureQuestionsList': [
  
            ],
            'OtherInsuranceList': [
              {
                'PreviousInsuraceCompName': null,
                'PreviousPolNo': null,
                'PreviousPolStartDate': null,
                'PreviousPolEndDate': null,
                'CoverTypeID': null,
                'SumInsured': null,
                'AccumulatedCumulativeBonus': null
              }
            ],
            'SumInsured': '',
            'IsAnyPhysicalDefect': null,
            'PhysicalDefectDetails': null,
            'ISPersonEarningMember': null,
            'TotalCapitalSI': null,
            'Hasanyclaimwithcurrentpreviousinsurer': null,
            'IsAnyDeclinedPolicy': null,
            'IsAnyotherhlthinspolicywiththeCompany': null,
            'OtherInformation': null,
            'Discount': null,
            'Loading': null,
            'IsAnyInsuredClaimed': null,
            'IsPrevInsuranceCancelled': null,
            'IsInsuredcoveredInHltPolicy': null,
            'NationalityId': null,
            'InsuredClaimDetails': null,
            'IsFitnessDeclaration': null,
            'IsInsuredConsumetobacco': null,
            'IsExistingIllness': null,
            'DoesInsuredSmokeOrConsumeAlchohol': null,
            'IsIllnessCancer': null,
            'IsIllnessOrganTransplant': null,
            'IsIllnessMultipleScelorsis': null,
            'IsIllnessBurns': null,
            'IsIllnessGraft': null,
            'IsIllnessHeartValve': null,
            'IsIllnessComa': null,
            'IsIllnessQuadriplegia': null,
            'IsIllnessBlind': null,
            'IsIllnessRenal': null,
            'IllnessCancerNames': null,
            'IllnessOrganTransplantNames': null,
            'IllnessMultipleScelorsisNames': null,
            'IllnessBurnsNames': null,
            'IllnessGraftNames': null,
            'IllnessHeartValveNames': null,
            'IllnessComaNames': null,
            'IllnessQuadriplegiaNames': null,
            'IllnessBlindNames': null,
            'IllnessRenalNames': null,
            'SplitApplicable': null,
            'IsMemberRenewed': null,
            'PreviousCumulativeBonusrate': null,
            'PreviousCumulativeBonus': null,
            'CumulativeBonus': null,
            'CumulativeBonusrate': null,
            'NewlyAddedMember': null,
            'CurrentYearNCD': null,
            'ApplicableSublimitforPortability': null,
            'IsInsuredDiagnosedEarlier': null,
            'EditGridRow': null
          }
        ],
        'Policy': {
          'Tenure': this.individual.policyTenure || 1,
          'PolicyStartDate': policyStartDate,
          'PolicyEndDate': policyEndDate,
          'Branch_Code': '9202',
          'BusinessTypeID': '1',
          'ProductCode': '2868',
          'ExternalSystemID': '1',
          'POSType': null,
          'POSAadhaarNumber': null,
          'POSPANNumber': null,
          'IsQuickQuote': false
        },
        'RiskDetails': {
          'PlanID': 82,
          'CategoryID': '0',
          'DOBofSeniorMost': CalculatePremiumforHealthNew.calculateBirthdate(this.individual.age),
          'MemberCount': 1,
          'Age': this.individual.age,
          'CoverTypeID': '1',
          'SumInsured': '500000',
          'PaymentBy': 'Proposer',
          'Tenure': 1,
          'InstallmentTypeID': null,
          'NameOfPhysician': null,
          'PhysicianContactNo': null,
          'PhysicianEmail': null,
          'NoofInstallments': null,
          'HealtExtension': {
            'Roomrenttype': null,
            'Voluntarydeductiblelimit': null,
            'Reductioninwaitingperiodlimit': null,
            'DCBLimit': null,
            'lstAddonDetails': [
              {
                'AddOnName': 'AD01',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD02',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD03',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD04',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD05',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD06',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD08',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD09',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD10',
                'IsSelected': false
              },
              {
                'AddOnName': 'AD11',
                'IsSelected': false
              }
            ]
          },
          'MultipleSI': 'No',
          'PlanwiseAddOn': 'false',
          'AddOnPlansType': 'None',
          'Bundling': 'false',
          'UpgradepremiumByZone': 'false',
          'Zone': null,
          'DeductibleSI': '0',
          'BundleSumInsured': null,
          'CoverType': 'Individual',
          'NoofMembersAbove21': null,
          'NoofMembersBelow21': 0
        },
        'PreviousInsuranceDetails': {
          'PrevInsuranceID': null,
          'PrevYearPolicyNo': null,
          'PrevYearPolicyStartDate': null,
          'PrevYearPolicyEndDate': null,
          'PreYearNCD': null,
          'prevYearTenure': null,
          'PrevYearSI': null,
          'PrevInsurerBranchID': null,
          'PrevInsurerContactNo': null,
          'PrevInsurerEmailID': null,
          'PrevInsurerAddress': null,
          'PrevInsurerBranchPincode': null,
          'PrevPolicyType': null
        },
        'PolicyDiscount': {
          'IsAnyEmployeeOfRelianceADAGroup': 'false',
          'EmployeeCode': '',
          'IsRGIHealthPolicyHolder': 'false',
          'IsRGIRetailPolicyHolder': 'false',
          'RGIHealthPolicyNo': null,
          'RGIRetailPolicyNo': null,
          'IsReferredByEmployee': 'false',
          'IsOtherInsuranceApplicable': 'false'
        },
        'CoverDetails': null,
        'ClaimStatus': 'false',
        'NoofSplits': null,
        'Issplitcase': null,
        'UserID': '100002API',
        'SourceSystemID': '100002API',
        'AuthToken': 'Pass@123',
        'TraceID': null,
        'IsQuickquote': 'false'
      }
      return this.http
        .post(url, JSON.stringify(params), { headers: headers })
        .pipe(catchError(this.handleError));
      
    }

  }


  private calculatePolicyStartDate(): string {
    const currentDate = new Date();
    return `${(currentDate.getDate() + 1).toString().padStart(2, '0')}/${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/${currentDate.getFullYear()}`;
  }

  private calculatePolicyEndDate(startDate: string, tenure: number): string {
    console.log(startDate); // 26/12/2023

    // Assuming startDate is in 'DD/MM/YYYY' format
    const [day, month, year] = startDate.split('/').map(Number);

    const startDateObj = new Date(year, month - 1, day - 1); // Month is 0-indexed
    const endDateObj = new Date(startDateObj);
    endDateObj.setFullYear(startDateObj.getFullYear() + tenure);

    const endDay = endDateObj.getDate().toString().padStart(2, '0');
    const endMonth = (endDateObj.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const endYear = endDateObj.getFullYear().toString();

    return `${endDay}/${endMonth}/${endYear}`;
  }



  private handleError(error: any): Observable<any> {
    console.error('An error occurred', error.message);
    return throwError(error.message || 'Server error');
  }

  static calculateBirthdate(age: number): string {
    const currentDate = new Date();
    const birthYear = currentDate.getFullYear() - age;

    // Adjusting the birthdate format to 'DD/MM/YYYY'
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = birthYear.toString();

    return `${day}/${month}/${year}`;
  }
}
@Injectable({
  providedIn: 'root',
})
export class ProposalCreationForHealth {

  individual: any = {
    type: 'I',
    gender: '1',
    age: '',
    adult_number: 1,
    policyTenure: 1,
    child_number: 0,
    userName: '',
    emailId: '',
    city_id: '',
    contactNumber: '',
    ped: '0',
    pincode: '400086',
    diseaseSelection:[
      {
        'DiseaseID':'14',
        'SufferingSince':'29/12/2018'
      }
    ]
  };



  constructor(private http: HttpClient) { }

  ProposalCreationForHealthToAPI(params:any): Observable<any> {
    const url = `${environment.api_reliance || '/reliance'}/api/Services/ProposalCreationForHealth`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    

    if (this.individual.type === 'I') {
      
      return this.http
        .post(url, JSON.stringify(params), { headers: headers })
        .pipe(catchError(this.handleError));
      
    }

  }






  private handleError(error: any): Observable<any> {
    console.error('An error occurred', error.message);
    return throwError(error.message || 'Server error');
  }

}
@Injectable({
  providedIn: 'root',
})
export class CkycService {

  private apiUrl = `${environment.api_ckyc || '/ckycService'}/Verify_CKYC_Details`;
  private subscriptionKey = '9338b32e0ed447b68b257ccdc6cfb0bb';

  constructor(private http: HttpClient) {}

  getPanVerified(data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Subscription-Key': this.subscriptionKey,
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(this.apiUrl, data, { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occurred', error.message);
    return throwError(error.message || 'Server error');
  }
}