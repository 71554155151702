import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { catchError, retry } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap, debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { constant } from '../constant';
import { CommonService } from '../services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LegalAndAdminPoliciesComponent } from '../legal-and-admin-policies/legal-and-admin-policies.component';
import { AuthService } from '../services/auth.service';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { Options } from 'ng5-slider';
import { CalculatePremiumforHealthNew } from '../services/reliance.service';

declare var $: any;


@Component({
  selector: 'app-health-quote',
  templateUrl: './health-quote.component.html',
  styleUrls: ['./health-quote.component.css']
})
export class HealthQuoteComponent implements OnInit {
  loading = false;
  premium8 = 0;
  premium_collection: any = {

  };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private http: HttpClient,
    private _location: Location,
    private authService: AuthService,
    private ngxLoader: NgxUiLoaderService,
    // reliance
    private healthService: CalculatePremiumforHealthNew
  ) { }


  ngOnInit(): void {
    this.startLoading();

    this.healthService.postDataToAPI().subscribe(
      (response) => {
        this.stopLoading();
        console.log('API Response:', response);

        if (response.ErrorMessages) {
          console.log('ErrMessages:', response.ErrorMessages.ErrMessages);
          console.log('ErrorDetect:', response.ErrorMessages.ErrorDetect);
          // Uncomment the line below if Toastr is used
          // this.toastr.error(response.ErrorMessages.ErrMessages);
        }

        if (response.Premium) {
          this.premium8 = parseFloat(response.Premium.FinalPremium) || 0;
          const gstComponent1 = parseFloat(response.Premium.LstTaxComponentDetails[0].Amount) || 0;
          const gstComponent2 = parseFloat(response.Premium.LstTaxComponentDetails[1].Amount) || 0;
          const coverage =parseFloat(response.RiskDetails.SumInsured) || 0;
          const premium_amout =parseFloat(response.Premium.BasicPremium) || 0;
          const premium_year =parseFloat(response.Policy.Tenure) || 0;
          const TotalDiscountAmount = Math.round(parseFloat(response.Premium.TotalDiscountAmount)) || 0;


          this.premium_collection = {
            coverage: coverage,
            premium_year: premium_year,
            premium_amout: premium_amout,
            gst: gstComponent1 + gstComponent2,
            final_premium: this.premium8,
            TotalDiscountAmount: TotalDiscountAmount,
          };

          localStorage.setItem('premium_collection', JSON.stringify(this.premium_collection));
        }
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error as needed
      }
    );
  }



  startLoading() {
    this.loading = true;
  }



  stopLoading() {
    this.loading = false;
  }

  handleButtonClick() {

    this.router.navigate(['/proposal']); // Replace 'new-page' with the actual route path
  }



}


